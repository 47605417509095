import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useProductsAPI } from 'api/products';
import { CsvUpload } from 'shared/CsvUpload';
import { DEFAULT_PRODUCTS_MAPPER } from './consts';
import { getSchema } from './schema';
import { getColumns } from './columns';

export const ProductsCsvUpload = () => {
  const { orgId } = useContext(AppContext);

  const [submittingForm, setSubmittingForm] = useState(false);

  const history = useHistory();

  const {
    operations: { bulkCreate },
  } = useProductsAPI({
    orgId,
  });

  const handleSubmit = async (values) => {
    setSubmittingForm(true);

    await bulkCreate.mutateAsync({
      data: values,
    });

    setTimeout(() => {
      history.push('/configure?tab=products');

      setSubmittingForm(false);
    }, 1000);
  };

  return (
    <CsvUpload
      onlyCreateMode={true}
      backLink="/configure?tab=products"
      entityName="products"
      isLoading={submittingForm}
      createSchema={getSchema()}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={() => DEFAULT_PRODUCTS_MAPPER}
      getColumnsWithCsvUploadState={getColumns}
    />
  );
};
