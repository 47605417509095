import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';

import { getCSVImports, createCSVImport as _createCSVImport, deleteCSVImport as _deleteCSVImport } from './requests';

export const CACHE_KEY = 'csvimports';

export const useCSVImportsAPI = ({ orgId, params, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getCSVImports({ orgId, params }),
    { enabled: autoFetch },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const createCSVImport = useMutation(({ data }) => _createCSVImport({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create CSV import record.');
    },

    onSuccess: () => {
      pushToast('Successfully created CSV import record!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const deleteCSVImport = useMutation(({ id }) => _deleteCSVImport({ orgId, csvImportId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete CSV import record.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted CSV import record!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data: data?.data,
    metadata: data?.metadata,
    error,
    isLoading,
    isFetching,
    operations: {
      createCSVImport,
      deleteCSVImport,
      refetch,
    },
  };
};
