import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { RECURRING_RECOGNITION_TYPES, SPREAD_TYPE } from 'consts/global';
import { useRevenueSpreadsAPI } from 'api/revenueSpreads';
import { SOURCE_TYPES } from '../TransactionsCsvUpload/consts';
import { DEFAULT_SPREADS_MAPPER } from './consts';
import { SpreadsCsvUpload } from './SpreadsCsvUpload';

dayjs.extend(utc);

export const EventBasedSpreadsCsvUpload = () => {
  const { organizations } = useContext(AppContext);

  const {
    operations: { bulkCreateSpreads },
  } = useRevenueSpreadsAPI({
    orgId: organizations[0].id,
    autoFetch: false,
  });

  const history = useHistory();

  const [submittingForm, setSubmittingForm] = useState(false);

  const handleSubmit =
    ({ transactions, transactionsIdSourceAndType }) =>
    async (values) => {
      setSubmittingForm(true);
      const spreadsForCreate = values?.map((spread) => {
        const transaction =
          transactionsIdSourceAndType?.type === SOURCE_TYPES.INTERNAL
            ? transactions?.find((transaction) => transaction?.id === spread?.transaction_id)
            : transactions?.find((transaction) => transaction?.provider_object_id === spread?.transaction_id);
        const customerId = spread?.customer_id
          ? spread?.customer_id
          : transaction?.customer_id ?? transaction?.transaction_customer_id;

        return {
          type:
            transaction?.recognition && RECURRING_RECOGNITION_TYPES.includes(transaction.recognition)
              ? SPREAD_TYPE.RECURRING
              : SPREAD_TYPE.NON_RECURRING,
          status: 'ACTIVE',
          amount: spread?.amount,
          date: dayjs(spread?.date).utc(true).format('YYYY-MM-DD'),
          seats: spread?.seats ?? 0,
          transaction_id: spread?.transaction_id,
          customer_id: customerId,
        };
      });

      const createdSpreads = await bulkCreateSpreads({ data: spreadsForCreate });

      setTimeout(() => {
        if (!!createdSpreads?.length) {
          history.push('/spreads');
        }

        setSubmittingForm(false);
      }, 1000);
    };

  return (
    <SpreadsCsvUpload
      handleSubmit={handleSubmit}
      submittingForm={submittingForm}
      entityName="spreads"
      defaultMapper={DEFAULT_SPREADS_MAPPER}
      eventRecognitionsOnly={true}
    />
  );
};
