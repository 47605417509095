import { Fragment, useContext } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { useBillingAgingReportAPI } from 'api/billing/hooks';
import { Flexer, Row, Column, Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { ReactComponent as DoughnutIcon } from 'images/doughnut-icon.svg';
import { AgingReportEmptyState } from 'views/Billing/Common/BillingEmptyState';

import { ARCustomersTable } from './ARCustomersTable';
import { numberFormatter } from 'utils/formatters';

const PageWrapper = styled.div`
  padding: 0 40px;
`;

const SummaryWrapper = styled(Flexer)`
  height: 116px;
  width: ${({ width }) => width ?? '100%'};

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  border: 1px solid var(--neutralGray);
  box-shadow: 2px 2px 12px var(--primaryBlack2);
  border-radius: 16px;
`;

export const ColoredDoughnutIcon = styled(DoughnutIcon)`
  circle {
    stroke: ${({ color }) => color ?? 'undefined'};
  }
`;

const SummaryBlock = styled(Column)`
  align-items: flex-start;

  padding: 20px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 90px;
  background: var(--accentGraySecond);
`;

const BoldText = styled.span`
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
`;

const AccentText = styled.span`
  opacity: 0.5;
`;

const KEY_TO_LABEL = {
  current: 'Current',
  total: 'Total',
};

const TOTAL_KEY = 'total';

const getPercentage = (value, total) => {
  if (total === 0) {
    return 0;
  }

  return Math.round((value / total) * 100);
};

export const BillingAgingReport = ({ exportScreenshotRef }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);
  const { data, isLoading } = useBillingAgingReportAPI({ orgId });

  const formatAmount = (amount) =>
    numberFormatter({
      type: NUMBER_FORMATS.CURRENCY,
      rawValue: amount,
      currency: currency,
      decimalPlaces: 2,
    });

  const allKeys = Object.keys(data?.all ?? {}).filter((key) => key !== TOTAL_KEY);
  const customerIds = Object.keys(data?.customers ?? {});

  const innerComponent = isLoading ? (
    <TimeLoader pageName="ar-aging-report" />
  ) : customerIds.length === 0 ? (
    <AgingReportEmptyState />
  ) : (
    <PageWrapper ref={exportScreenshotRef}>
      <Row>
        <SummaryWrapper width="83%">
          {allKeys.map((key, index) => (
            <Fragment key={key}>
              <SummaryBlock>
                <Row>
                  <ColoredDoughnutIcon />
                  <Spacer width="8px" />
                  {KEY_TO_LABEL[key] ?? key}
                </Row>
                <Spacer height="18px" />
                <BoldText>{formatAmount(data?.all?.[key])}</BoldText>
                <AccentText>{getPercentage(data?.all?.[key], data?.all?.[TOTAL_KEY])}% of total</AccentText>
              </SummaryBlock>
              {index < allKeys.length - 1 && <VerticalDivider />}
            </Fragment>
          ))}
        </SummaryWrapper>
        <Spacer width="2%" />
        <SummaryWrapper width="15%">
          <SummaryBlock>
            <Row>
              <ColoredDoughnutIcon color="var(--primaryGreen)" />
              <Spacer width="8px" />
              {KEY_TO_LABEL[TOTAL_KEY]}
            </Row>
            <Spacer height="18px" />
            <BoldText>{formatAmount(data?.all?.[TOTAL_KEY])}</BoldText>
            <span>
              <BoldText>{customerIds.length}</BoldText> customers
            </span>
          </SummaryBlock>
        </SummaryWrapper>
      </Row>
      <Spacer height="16px" />
      <ARCustomersTable data={data} formatAmount={formatAmount} />
    </PageWrapper>
  );

  return <TimeLoaderContainer>{innerComponent}</TimeLoaderContainer>;
};
