import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ViewScheduleContainer, HorizontalLine, LinkToInvoicingSchedule } from './styles';

export const TransactionLinkToInvoicingSchedule = ({ invoicingScheduleId }) => {
  const { orgId } = useContext(AppContext);
  const { openInvoicingScheduleModal } = useContext(TransactionContext);
  // TODO: Call API with just count of invoices per invoicing schedule? This call is too heavy
  const { data: invoicingSchedule } = useInvoicingScheduleAPI({ orgId, invoicingScheduleId, scopes: ['invoices'] });

  return (
    <ViewScheduleContainer>
      <LinkToInvoicingSchedule
        data-cy="transaction__view-schedule-button"
        onClick={() => openInvoicingScheduleModal({ invoicingSchedule: { id: invoicingScheduleId } })}
      >
        View Invoicing Schedule
      </LinkToInvoicingSchedule>
      <HorizontalLine />
      <span>{invoicingSchedule?.invoices?.length ?? 0} invoices</span>
    </ViewScheduleContainer>
  );
};
