import { findCustomerSubscriptId } from 'views/Transactions/TransactionsCsvUpload/utils';

const BILLING_ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'state', 'postalcode', 'country'];

export const getFormFinalCustomers = ({
  formCustomers,
  subscriptCustomers,
  subscriptParentCustomers,
  uploadCsvCustomersIdSource,
  customerIdSourceAndType,
  parentCustomerIdSourceAndType,
  organizations,
}) =>
  formCustomers.map((value) => {
    const finalCustomer = {
      ...value,
      id: value.customer_id
        ? findCustomerSubscriptId({
            customerId: value.customer_id,
            uploadCsvCustomersIdSource,
            customerIdSourceAndType,
            customers: subscriptCustomers,
          })
        : null,
      organization_id: organizations[0].id,
      // TODO [TC 2023-06-13]: Fuzzy matching is needed to support matching to the customer id
      parent_customer_id: value.parent_customer_id
        ? findCustomerSubscriptId({
            customerId: value.parent_customer_id,
            uploadCsvCustomersIdSource,
            customerIdSourceAndType: parentCustomerIdSourceAndType,
            customers: subscriptParentCustomers,
          })
        : null,
      invoicing_details: {},
    };

    const finalCustomerKeys = new Set(Object.keys(finalCustomer));

    if (finalCustomerKeys.has('contacts') && !!finalCustomer.contacts) {
      finalCustomer.invoicing_details.contacts = finalCustomer.contacts.split('|');
    }
    delete finalCustomer.contacts;

    for (const billingAddressField of BILLING_ADDRESS_FIELDS) {
      if (finalCustomerKeys.has(billingAddressField) && !!finalCustomer[billingAddressField]) {
        finalCustomer.invoicing_details[billingAddressField] = finalCustomer[billingAddressField];
      }
      delete finalCustomer[billingAddressField];
    }

    delete finalCustomer.customer_id;
    delete finalCustomer.rowIndex;

    return finalCustomer;
  });
