import React, { useContext } from 'react';
import styled from 'styled-components';

import { pluralize } from 'utils/stringUtils';
import { Centerer, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { MultiselectRibbon } from 'components/Blocks';

import { InvoiceLineItem } from './InvoiceLineItem';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { BulkInvoicesActions } from './InvoiceActions/BulkInvoicesActions';
import { InvoicesWarns } from './InvoicesWarns';

const ScrollContainer = styled(FlexerColumn)`
  height: 100%;
  overflow-y: auto;
  position: relative;
  min-height: 30vh;
  max-height: 50vh;
  width: 100%;
  border-top: 1px solid var(--primaryBlack3);
`;

const LoaderWrapper = styled(Centerer)`
  margin-top: 100px;
`;

const ListButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;

  span {
    opacity: 0.5;
  }
`;

const EmptyState = styled(Centerer)`
  font-size: 14px;
  color: var(--primaryBlack20);
  height: 100%;
`;

const EmptyStateText = styled.div`
  text-align: center;
  width: 160px;
`;

const SelectAllButton = styled.div`
  cursor: pointer;
  font-weight: 700;
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

export const InvoicesTab = ({ invoices, loading }) => {
  const {
    invoicingScheduleFormValues,
    bulkChangeInvoices,
    setBulkChangeInvoices,
    customerDetails,
    invoicesCreatedInGl,
  } = useContext(InvoicingScheduleContext);

  const isEveryInvoiceSelected = invoices.every((invoice) => bulkChangeInvoices.find((i) => i.id === invoice.id));

  return (
    <>
      <InvoicesWarns invoices={invoices} />

      <ListButtons>
        <SelectAllButton onClick={() => setBulkChangeInvoices(isEveryInvoiceSelected ? [] : invoices)}>
          {isEveryInvoiceSelected ? 'Deselect all' : 'Select all'}
        </SelectAllButton>
        <span>{invoicesCreatedInGl?.length} imported</span>
      </ListButtons>

      {loading ? (
        <LoaderWrapper>
          <CircleLoader name="invoices" width={48} height={48} />
        </LoaderWrapper>
      ) : invoicingScheduleFormValues?.invoices?.length ? (
        <>
          <ScrollContainer data-cy={'billing__invoice_schedule-modal__invoice-list'}>
            {invoices.map((invoice, index) => (
              <InvoiceLineItem
                invoice={invoice}
                invoices={invoices}
                index={index}
                key={invoice.id ?? invoice.unsavedId}
                customer={customerDetails}
              />
            ))}
          </ScrollContainer>

          {!!bulkChangeInvoices?.length ? (
            <MultiselectRibbon
              dataCy="billing__invoice_schedule-modal__invoice-list__bulk-actions"
              left="210px"
              label={`${pluralize(bulkChangeInvoices?.length, 'invoice')}`}
              insertActionsDirectly
              actions={<BulkInvoicesActions />}
              onResetSelection={() => setBulkChangeInvoices([])}
            />
          ) : null}
        </>
      ) : (
        <EmptyState>
          <EmptyStateText>Regenerate the schedule or add new invoices</EmptyStateText>
        </EmptyState>
      )}
    </>
  );
};
