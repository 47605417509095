import React, { Fragment, useContext } from 'react';
import { AppContext } from 'AppContext';
import { useContractAPI } from 'api/contracts/hooks';
import { useTransactionsAPI } from 'api/transactions';
import { Centerer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { ContractSinglePageHeader } from './ContractSinglePageHeader';
import { ContractDetails } from './ContractDetails';
import { ContractTransactionsTable } from './ContractTransactionsTable';
import { ContractTabs } from './ContractTabs';

export const ContractSingleContent = ({ contractId }) => {
  const { orgId } = useContext(AppContext);

  const {
    data: contractData,
    isLoading: isLoadingContract,
    isFetching: isFetchingContract,
    operations: { archiveContract },
  } = useContractAPI({
    orgId,
    contractId,
  });

  const {
    data: transactionsData,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useTransactionsAPI({ orgId, filters: { body: { contractIds: [contractId] } } });

  if (isLoadingContract || isFetchingContract || isLoadingTransactions || isFetchingTransactions) {
    return (
      <Centerer height="100vh">
        <SizedLoader size={40} />
      </Centerer>
    );
  }

  return (
    <Fragment>
      <ContractSinglePageHeader contractData={contractData} archiveContract={archiveContract} />

      <ContractDetails contractData={contractData} transactionsData={transactionsData} />

      <ContractTransactionsTable contractData={contractData} transactionsData={transactionsData} />

      <ContractTabs contractId={contractId} contractData={contractData} transactionsData={transactionsData} />

      {/* modal to add transaction(s) */}
      {/* modal to remove transaction */}
    </Fragment>
  );
};
