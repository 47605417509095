import React, { useContext, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { useCohortsAPI } from 'api/cohorts';
import { getSegmentOptions } from 'models/cohorts';
import { Spacer } from 'components/Core';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { UnconfirmedTransactionsBanner } from 'shared/Banners';
import { NoIntegrations, NoItems } from 'shared/ErrorBars';
import { HEADER_TITLES } from 'shared/Layout';
import { useSyncedExport, PERIOD_TYPE_OPTIONS, COHORT_INSTALL_BY_OPTIONS } from 'shared/SyncedExports';
import { useLinkShare } from 'shared/LinkShare';
import { GlobalTogglesContextProvider, GlobalTogglesFloatingContainer } from 'shared/GlobalToggles';
import { RevenueTypeFilter } from 'shared/Filters';
import { FiltersAndSegmentBy } from 'shared/Filters/FiltersAndSegmentBy';
import { HeaderReports } from 'shared/Reports';
import { SpreadsModal } from 'views/Cohorts/SpreadsModal';
import { getValueFromlabel } from 'utils/objectUtils';
import { PAGE_CONTEXT_VALUES, useToasts } from 'utils/hooks';
import { DEFAULT_FILTER_MONTHS_FROM_START } from 'consts/global';
import { useCachedMetrics, useInvariantCheck } from 'utils/hooks';
import { InsightsContext } from 'shared/Insights/InsightsContext';
import { InsightCards } from 'shared/Insights/InsightCard/InsightCards';
import { CohortsContext } from './CohortsContext';
import { CohortFilter } from 'views/Cohorts/CohortFilter';
import { CohortsGraph } from './CohortsGraph';
import { CohortsTable } from './CohortsTable';
import { COHORT_BY_OPTIONS, INSTALL_BY_OPTIONS } from './consts';
import { checkAverageAndTotalRows, checkSumOfSegments } from './invariantChecks';

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  min-height: 700px;
  padding-bottom: 40px;
`;

export const CohortsPage = () => {
  dayjs.extend(utc); //without this, time comparisons get messed up
  dayjs.extend(quarterOfYear); //for quarterly cohorts

  const { pushToast } = useToasts();
  const { organizations, orgId, integrations, orgConfigs } = useContext(AppContext);
  const { getInsights } = useContext(InsightsContext);

  const pageState = useContext(CohortsContext);
  const {
    isARR,
    optimisticAnalytics,
    isCommitted, // used only for invariant checking and to show the warning banner
    rollup,

    dataFilter,
    setDataFilter,
    metadataFilter,
    setMetadataFilter,

    totalAmounts,

    cohortsData,
    setCohortsData,
    setCohortResolution,

    showSpreadsModal,
    setShowSpreadsModal,
    cohortStartDate,
    cohortEndDate,
    selectedMonth,
    cohortCustomerIds,
    segmentDescriptor,
  } = pageState;

  const { cohortInstallBy, cohortInstallSecondBy, cohortBy, cohortSegmentBy, monthsFromStart } = dataFilter;

  const segmentOptions = getSegmentOptions({ orgConfigs });

  const exportScreenshotRef = useRef();

  const {
    loading,
    data: APICohortsData,
    isFetching,
    operations: { refetch },
  } = useCohortsAPI({
    orgId,
    params: {
      orgId: organizations[0].id,
      startmo: dataFilter.startMonth.getMonth() + 1,
      startyear: dataFilter.startMonth.getFullYear(),
      endmo: dataFilter.endMonth.getMonth() + 1,
      endyear: dataFilter.endMonth.getFullYear(),
      byDate: (cohortBy ?? COHORT_BY_OPTIONS.subscriptionDate) === 'subscription',
      spreadTypes: dataFilter.revenueTypes,
      periodType: dataFilter.resolution.slice(0, -2).toLowerCase(),
      segmentBy: cohortSegmentBy ?? '',
      optimisticAnalytics,
      rollup,
      isCommitted,
      transactionMetadata: JSON.stringify(metadataFilter?.transactionMetadata),
      customerMetadata: JSON.stringify(metadataFilter?.customerMetadata),
      cohortInstallBy,
      cohortInstallSecondBy,
      monthsFromStart: (monthsFromStart ?? DEFAULT_FILTER_MONTHS_FROM_START) - 1,
    },
  });

  useEffect(() => {
    if (dataFilter.revenueTypes.length === 0) {
      setCohortsData({});
      return;
    }

    if (APICohortsData) {
      setCohortsData(APICohortsData);
      setCohortResolution(dataFilter.resolution);
    }
  }, [APICohortsData, dataFilter.resolution, dataFilter.revenueTypes.length, setCohortResolution, setCohortsData]);

  /*
    All cohorts are by subscription date
    For now we will not have a way to change it
    setCohortBy
  */

  const { LinkShareButton, LinkShareModal } = useLinkShare({ headerTitle: HEADER_TITLES.cohorts });
  const { SyncedExportInfoBar, SyncedExportModal, ExportButton } = useSyncedExport({
    exportScreenshotRef,
    orgId: organizations[0].id,
    type: 'cohorts',
    customization: ({ selectAllData }) => ({
      startMonth: dayjs(dataFilter.startMonth).format('YYYY-MM'),
      endMonth: dayjs(dataFilter.endMonth).format('YYYY-MM'),
      ...(!selectAllData && metadataFilter),
      cohortSegmentBy: selectAllData ? null : cohortSegmentBy,
      cohortInstallBy: getValueFromlabel({ options: COHORT_INSTALL_BY_OPTIONS, label: cohortInstallBy }),
      cohortPeriodType: getValueFromlabel({ options: PERIOD_TYPE_OPTIONS, label: dataFilter.resolution }),
      monthsFromStart: monthsFromStart - 1,
      cohortDisplayingTotal: totalAmounts,
    }),
    isFilterOn: (metadataFilter && Object.values(metadataFilter).length !== 0) || cohortSegmentBy,
    startDateKey: 'startMonth',
    endDateKey: 'endMonth',
  });

  useInvariantCheck({
    readyData:
      cohortsData && cohortInstallBy === INSTALL_BY_OPTIONS.revenueDollar && cohortsData[cohortInstallBy]
        ? cohortsData[cohortInstallBy]
        : null,
    checkers: [checkAverageAndTotalRows, checkSumOfSegments],
  });

  useCachedMetrics({
    // Cohorts do not currently respect committed toggle
    readyData:
      !isCommitted && cohortsData && cohortsData[cohortInstallBy] ? cohortsData[cohortInstallBy].cohortsData : null,
    getValue: ({ readyData }) =>
      Object.entries(readyData).reduce(
        (acc, [cohortKey, { monthTotals, startingCustomerCount }]) =>
          Object.assign(acc, {
            [cohortKey]: isARR
              ? {
                  customerCount: startingCustomerCount,
                  totalAmount: monthTotals[0] * 12,
                }
              : {
                  customerCount: startingCustomerCount,
                  totalAmount: monthTotals[0],
                },
          }),
        {},
      ),
    description: 'ARPA New calculated from Cohorts',
    storageKey: 'cohortsNewARPA',
    context: 'Cohorts',
  });

  const hasTransactions = organizations && parseInt(organizations[0]?.transactionCount) > 0;
  if (integrations.length > 0 && !hasTransactions) {
    return <NoIntegrations />;
  }

  const insights = getInsights({
    segmentBy: cohortSegmentBy,
    chartType: 'cohort',
    customization: {
      ...dataFilter,
      metadataFilter,
      isARR,
      optimisticAnalytics,
      isCommitted,
      rollup,
    },
  });

  return (
    <MainContainer>
      <HeaderReports
        activePage="cohorts"
        headerTitle={HEADER_TITLES.cohorts}
        headerRight={
          <>
            <LinkShareButton />
            <Spacer width="10px" />
            <ExportButton />
          </>
        }
        page={PAGE_CONTEXT_VALUES.cohort.key}
        pageState={pageState}
      />

      <SyncedExportInfoBar />

      <GlobalTogglesContextProvider urlState={pageState}>
        <FiltersAndSegmentBy
          currentPageSegmentKey="cohortSegmentBy"
          isARR={isARR}
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          resolutionDropdownEnabled
          showConditions={true}
          metadataFilter={metadataFilter}
          setMetadataFilter={setMetadataFilter}
          segmentOptions={segmentOptions}
          segmentName="cohortSegmentBy"
          relativeMonthSelectorForTimeseries
          showTransactionMetadata={false}
          allowSegment
        >
          <CohortFilter />
          <RevenueTypeFilter />
        </FiltersAndSegmentBy>

        {cohortsData?.hasUnconfirmedTransactions && <UnconfirmedTransactionsBanner />}

        <TimeLoaderContainer isLoading={loading || isFetching}>
          {loading || isFetching ? (
            <TimeLoader pageName="cohorts" />
          ) : (
            <>
              {Object.keys(cohortsData?.[cohortInstallBy]?.cohortsData ?? {}).length > 0 ? (
                <GlobalTogglesFloatingContainer>
                  {insights?.length ? (
                    <InsightCards insights={insights} cohortData={cohortsData[cohortInstallBy]} />
                  ) : null}
                  <CohortsGraph />
                  <CohortsTable exportScreenshotRef={exportScreenshotRef} />
                </GlobalTogglesFloatingContainer>
              ) : (
                <NoItems
                  buttonCb={async () => {
                    await refetch();
                    pushToast('Cohorts refreshed', 'success');
                  }}
                  filterState={dataFilter}
                  organization={organizations[0].id}
                  cohortBy={cohortBy}
                />
              )}
            </>
          )}
        </TimeLoaderContainer>
      </GlobalTogglesContextProvider>

      <LinkShareModal />
      <SyncedExportModal />

      {showSpreadsModal && (
        <SpreadsModal
          organizations={organizations}
          closeModal={() => setShowSpreadsModal(false)}
          cohortStartDate={cohortStartDate}
          cohortEndDate={cohortEndDate}
          customerIds={cohortCustomerIds}
          segmentDescriptor={segmentDescriptor}
          setShowSpreadsModal={setShowSpreadsModal}
          selectedMonth={selectedMonth}
          spreadTypes={dataFilter.revenueTypes}
          metadataFilter={metadataFilter}
          rollup={rollup}
          isCommitted={isCommitted}
        />
      )}
    </MainContainer>
  );
};
