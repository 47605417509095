import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { getDiscountsFromSearch } from 'api/usageBasedEngine';
import { AppContext } from 'AppContext';
import { InlineButton } from 'components/Buttons';
import { FormikCustomSelector, FormikCustomCheckbox } from 'components/Controls';
import { Centerer, Flexer, Spacer } from 'components/Core';
import { CirclePlusIcon, EditCircleIcon } from 'components/Icons';
import { DiscountEditModal } from './DiscountEditModal';
import { DiscountTypeValue } from './DiscountTypeValue';
import { FormRow } from './styles';

const DiscountRow = styled(FormRow)`
  > div:first-child {
    width: 46%;
  }

  > div:not(:first-child) {
    width: 27%;
  }
`;

const EmptyDiscountOption = styled(Flexer)`
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--primaryBlack30);
    }
  }
`;

const LabelText = styled.span`
  opacity: 0.4;
`;

const EditDiscountButton = styled(Flexer)`
  gap: 8px;
  cursor: pointer;
`;

const StyledEditCircleIcon = styled(EditCircleIcon)`
  width: 16px !important;
  height: 16px !important;
`;

export const FormikDiscountSection = ({ onDiscountUpdated }) => {
  const { orgId } = useContext(AppContext);
  const { setFieldValue, values } = useFormikContext();

  const createNewDiscountCodeOption = useMemo(
    () => ({
      label: (
        <EmptyDiscountOption>
          <CirclePlusIcon />
          Create new
        </EmptyDiscountOption>
      ),
      value: null,
    }),
    [],
  );

  const [discountToEdit, setDiscountToEdit] = useState(null);

  const handleDiscountEdit = (event) => {
    event.preventDefault();

    setDiscountToEdit({
      id: values?.discount_id,
      code: values?.discount_code,
      type: values?.discount_type,
      value: values?.discount_value,
    });
  };

  return (
    <>
      <FormRow>
        <FormikCustomCheckbox
          name="has_discount"
          label="Apply a discount"
          fontSize="14px"
          bold
          data-cy="formik-discount-section__has-discount"
        />
      </FormRow>
      {values?.has_discount && (
        <>
          <DiscountRow>
            <FormikCustomSelector
              isPaginateable
              label={
                <Centerer gap="8px">
                  <LabelText>Discount Code</LabelText>
                  {values?.discount_id && (
                    <EditDiscountButton onClick={handleDiscountEdit} data-cy="formik-discount-section__edit-discount-button">
                      <StyledEditCircleIcon />
                      <InlineButton isSecondary>Edit</InlineButton>
                    </EditDiscountButton>
                  )}
                </Centerer>
              }
              labelOpacity={1}
              name="discount_id"
              value={
                values?.discount_id
                  ? {
                      label: values?.discount_code,
                      value: values?.discount_id,
                    }
                  : createNewDiscountCodeOption
              }
              loadOptions={async (searchQuery) => {
                const result = await getDiscountsFromSearch({ searchQuery, orgId });
                return {
                  ...result,
                  options: [createNewDiscountCodeOption, ...result.options],
                };
              }}
              handleChange={(option) => {
                setFieldValue('discount_id', option ? option.value : null);
                if (option.data) {
                  setFieldValue('discount_id', option.data.id);
                  setFieldValue('discount_code', option.data.code);
                  setFieldValue('discount_type', option.data.type);
                  setFieldValue('discount_value', option.data.value);
                }
              }}
            />
            <DiscountTypeValue values={values} />
          </DiscountRow>
          <Spacer height="16px" />
        </>
      )}

      {discountToEdit && (
        <DiscountEditModal
          discount={discountToEdit}
          onClose={() => setDiscountToEdit(null)}
          onDiscountUpdated={onDiscountUpdated}
        />
      )}
    </>
  );
};
