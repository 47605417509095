import React, { useContext, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { Loader } from 'components/Loaders';
import { ModalContainer, Modal, ModalFooter } from 'components/Modal';
import { ISO_CODE_TO_SYMBOL, RECOGNITION_TYPES } from 'consts/global';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import { ReactComponent as RelatedHelpIcon } from 'images/related-help.svg';
import { useProductsAPI } from 'api/products';
import { useImportsAPI } from 'api/imports';
import { PRODUCT_IMPORT_METADATA_KEYS, PRODUCT_METADATA_KEYS, SERVICE_WITH_IS_TAXABLE } from 'views/Billing/consts';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { Flexer } from 'components/Core';
import { getServiceCategory } from 'models/integration';
import { GeneralLedgerOptions } from './GeneralLedgerOptions';
import { CRMOptions } from './CRMOptions';

const ProductHeader = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  b {
    font-weight: 900;
  }
`;

const ProductActionItem = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const ProductWrapper = styled.div`
  padding: 0 50px;
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;
const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled.div`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
`;

const SaveButton = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};

  svg {
    margin-left: 10px;
  }
`;

export const StyledRelatedHelpIcon = styled(RelatedHelpIcon)`
  width: 16px;
  height: 16px;
  g {
    opacity: 1;
  }
  path {
    fill: var(--primaryBlack);
  }
  &:hover {
    g {
      opacity: 1;
    }
  }
`;

export const ProductActionsModal = ({ action, product, onClose }) => {
  const {
    orgId,
    integrations,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    data,
    isLoading,
    refetch,
    operations: { createProduct, updateProduct },
  } = useProductsAPI({ orgId, params: { scopes: ['imports'] } });
  const { productCategories } = data ?? {};

  const {
    operations: { upsertImport, deleteImport },
  } = useImportsAPI({
    orgId,
    autoFetch: false,
    enableToasts: false,
  });

  const glIntegrations = (integrations ?? []).filter((integration) => integration.type === INTEGRATION_TYPES.GL);
  const hasGlIntegration = glIntegrations.length !== 0;

  const existingGlImports = useMemo(
    () =>
      (product?.imports ?? [])
        .filter((productImport) =>
          glIntegrations
            .map((integration) => integration.id?.toString())
            .includes(productImport.integration_id?.toString()),
        )
        .sort(
          (a, b) =>
            a?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER] -
            b?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER],
        ),
    [glIntegrations, product?.imports],
  );

  const crmIntegration = (integrations ?? []).find((integration) => integration.type === INTEGRATION_TYPES.CRM);
  const existingCRMImports = useMemo(
    () =>
      (product?.imports ?? [])
        .filter((productImport) => productImport.provider_name === crmIntegration?.service)
        .sort(
          (a, b) =>
            a?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER] -
            b?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER],
        ),
    [crmIntegration, product?.imports],
  );

  const initialValues = useMemo(() => {
    const glImports =
      !existingGlImports.length && hasGlIntegration
        ? [
            {
              integration_id: glIntegrations[0]?.id,
              service: glIntegrations[0].service,
            },
          ]
        : existingGlImports.map((productImport) => ({
            id: productImport.id,
            integration_id: productImport.integration_id,
            provider_object_id: productImport.provider_object_id,
            name: productImport.metadata?.product_name,
            service: productImport.service,
            metadata: {
              ...productImport.metadata,
            },
          }));

    const crmImports =
      !existingCRMImports.length && crmIntegration
        ? [
            {
              integration_id: crmIntegration?.id,
              service: crmIntegration.service,
            },
          ]
        : existingCRMImports.map((productImport) => ({
            id: productImport.id,
            integration_id: productImport.integration_id,
            provider_object_id: productImport.provider_object_id,
            name: productImport.metadata?.product_name,
            service: productImport.service,
            metadata: {
              ...productImport.metadata,
            },
          }));

    return {
      ...product,
      glImports,
      crmImports,
    };
  }, [existingGlImports, glIntegrations, hasGlIntegration, crmIntegration, existingCRMImports, product]);

  const handleSaveProduct = async (values) => {
    setIsUpdating(true);
    let productToReturn;
    if (action === 'create') {
      productToReturn = await createProduct.mutateAsync({ data: values });
    } else {
      const updateData = { ...values };
      if (product.archived_at) updateData.archived_at = null;

      productToReturn = await updateProduct.mutateAsync({ productId: values.id, data: updateData });
    }

    const importsToDelete = [
      ...existingGlImports.filter(
        (existingGlImport) =>
          !values.glImports?.find(
            (glImport) =>
              glImport.provider_object_id === existingGlImport.provider_object_id &&
              glImport.integration_id === existingGlImport.integration_id &&
              glImport.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT] ===
                existingGlImport.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT],
          ),
      ),
      ...existingCRMImports.filter(
        (existingCRMImport) =>
          !values.crmImports?.find(
            (crmImport) =>
              crmImport.provider_object_id === existingCRMImport.provider_object_id &&
              crmImport.integration_id === existingCRMImport.integration_id,
          ),
      ),
    ];

    if (importsToDelete.length) {
      await Promise.all(importsToDelete.map(({ id }) => id && deleteImport.mutateAsync({ importId: id })));
    }

    if ([...values.glImports, ...values.crmImports].length) {
      const updatedImports = (
        await Promise.all(
          [...values.glImports, ...values.crmImports].map(
            (importData, index) =>
              importData.integration_id &&
              importData.provider_object_id &&
              upsertImport.mutateAsync({
                integrationId: importData.integration_id,
                data: {
                  chiffer_object_name: 'product',
                  chiffer_object_id: productToReturn.id,
                  provider_object_id: importData.provider_object_id,
                  metadata: {
                    ...importData.metadata,
                    product_name: importData.name,
                    [PRODUCT_IMPORT_METADATA_KEYS.DISPLAY_ORDER]: index,
                  },
                },
              }),
          ),
        )
      ).filter((updatedImport) => !!updatedImport);

      productToReturn.imports = updatedImports;
    } else {
      productToReturn.imports = [];
    }

    if (importsToDelete.length || [...values.glImports, ...values.crmImports].length) await refetch();

    setIsUpdating(false);

    onClose(productToReturn);
  };

  const currencySymbol = ISO_CODE_TO_SYMBOL[currencyISOCode] ?? '$';

  return (
    <ModalContainer>
      <Formik initialValues={initialValues} onSubmit={handleSaveProduct}>
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Modal
              overflow="visible"
              width="800px"
              minHeight="320px"
              data-cy="product-actions-modal"
              height="auto"
              maxHeight="auto"
            >
              {isLoading ? (
                <div className="w-100 flexer">
                  <Loader containerStyles={{ width: 40 }} />
                </div>
              ) : (
                <>
                  <ProductWrapper>
                    <ProductHeader data-cy="product-actions-modal__title">
                      <b>{action === 'create' ? 'Create' : 'Edit'}</b> Product
                    </ProductHeader>

                    <Flexer gap="8px">
                      <ProductActionItem>
                        <FormikCustomInput
                          name="name"
                          placeholder="Enter name..."
                          style={{ direction: 'ltr', height: 36, borderRadius: '8px' }}
                          data-cy="product-actions-modal__name-input"
                          label="Name"
                        />
                      </ProductActionItem>

                      <ProductActionItem>
                        <FormikCustomInput
                          name="display_name"
                          placeholder="Enter display name..."
                          style={{ direction: 'ltr', height: 36, borderRadius: '8px' }}
                          data-cy="product-actions-display-name-input"
                          label="Display Name"
                          labelTooltipContent="Optional field. An alternative product display name used in invoices, such as Subscript invoice PDFs or payments sent through Subscript auto-charge."
                        />
                      </ProductActionItem>
                    </Flexer>

                    <Flexer gap="8px">
                      <ProductActionItem>
                        <FormikCustomSelector
                          dataCy="product-actions-modal-category"
                          name="product_category_id"
                          placeholder="Select category..."
                          options={productCategories?.map((productCategory) => ({
                            label: productCategory.name,
                            value: parseInt(productCategory.id),
                          }))}
                          label="Category"
                        />
                      </ProductActionItem>

                      <ProductActionItem>
                        <FormikCustomSelector
                          dataCy="product-actions-modal-recognition"
                          label="SaaS Rev. Recognition"
                          name="recognition"
                          placeholder="Select type..."
                          options={Object.values(RECOGNITION_TYPES).map((t) => ({ label: t, value: t }))}
                        />
                      </ProductActionItem>

                      <ProductActionItem>
                        <FormikCustomInput
                          name="standalone_selling_price"
                          type="number"
                          precision={2}
                          placeholder="Enter price..."
                          style={{ direction: 'ltr', height: 36, borderRadius: '8px' }}
                          data-cy="product-actions-modal__standalone_selling_price-input"
                          label="Standalone Selling Price"
                          suffix={currencySymbol}
                        />
                      </ProductActionItem>

                      {glIntegrations.some((integration) =>
                        SERVICE_WITH_IS_TAXABLE.includes(getServiceCategory(integration.service)),
                      ) && (
                        <ProductActionItem>
                          <FormikCustomSelector
                            dataCy="product-actions-modal-isTaxable"
                            label="Is Taxable"
                            name={`metadata.${PRODUCT_METADATA_KEYS.IS_TAXABLE}`}
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                          />
                        </ProductActionItem>
                      )}
                    </Flexer>

                    <Flexer>
                      <ProductActionItem>
                        <FormikCustomInput
                          name="description"
                          placeholder="Enter description..."
                          style={{ direction: 'ltr', height: 36, borderRadius: '8px' }}
                          data-cy="product-actions-modal__description-input"
                          label="Description"
                        />
                      </ProductActionItem>
                    </Flexer>

                    {hasGlIntegration && (
                      <ProductActionItem>
                        <GeneralLedgerOptions
                          values={values}
                          setFieldValue={setFieldValue}
                          existingGlImports={existingGlImports}
                        />
                      </ProductActionItem>
                    )}

                    {crmIntegration && (
                      <ProductActionItem>
                        <CRMOptions
                          crmIntegration={crmIntegration}
                          values={values}
                          setFieldValue={setFieldValue}
                          existingCRMImports={existingCRMImports}
                        />
                      </ProductActionItem>
                    )}
                  </ProductWrapper>
                  <FormFooter>
                    <FormButtonsRow>
                      <CancelButton onClick={onClose}>Cancel</CancelButton>
                      <SaveButton
                        unactive={!values?.name || !values?.recognition || isUpdating}
                        onClick={submitForm}
                        data-cy="product-actions-modal__save-button"
                      >
                        {action === 'create' ? 'Save' : product.archived_at ? 'Update & Restore' : 'Update'}
                        <CheckIcon height="20px" style={{ marginLeft: '10px' }} />
                      </SaveButton>
                    </FormButtonsRow>
                  </FormFooter>
                </>
              )}
            </Modal>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

export const useProductActionsModal = ({ refetch } = {}) => {
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openParams) => {
      setOpenParams(openParams);
      setShowModal(true);
    },
    [setShowModal],
  );
  const closeModal = useCallback(() => {
    refetch && refetch();
    setShowModal(false);
  }, [refetch]);

  const Modal = useCallback(() => (showModal ? <ProductActionsModal onClose={closeModal} {...openParams} /> : <></>), [
    showModal,
    closeModal,
    openParams,
  ]);

  return {
    openModal,
    ProductActionsModal: Modal,
  };
};
