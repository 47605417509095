import { useState } from 'react';
import { getAppSetting, setAppSetting } from 'models/appSettings';

export const useAppSetting = (key, options = {}) => {
  const [storedValue, setStoredValue] = useState(() => getAppSetting(key) ?? options.initialValue);

  const setValue = (value) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    setStoredValue(valueToStore);
    setAppSetting(key, valueToStore);
    (options?.afterSet)?.();
  };

  return [storedValue, setValue];
};
