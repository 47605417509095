import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { MAGIC_METADATA, EVENT_RECOGNITION_TYPES } from 'consts/global';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { normalizeFrequency } from 'models/invoicingSchedule';
import { getIntegrationByService, getServiceCategory } from 'models/integration';
import { mapFilter } from 'utils/arrayUtils';
import {
  SENDER_OPTIONS,
  SENDER_MAILER_OPTIONS,
} from 'views/Configuration/Tabs/SubscriptBillingTab/BillingSenderSetttings/consts';
import {
  INVOICE_ITEM_TYPES,
  INVOICE_MAGIC_METADATA,
  INVOICING_FREQUENCIES,
  INVOICING_FREQUENCY_TO_MONTH_LENGTH,
  REMINDER_ERROR_METADATA_FIELDS,
} from './consts';

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/advancedFormat'));

export const getInvoiceNotSentReminders = ({ invoice }) => invoice?.reminders?.filter((reminder) => !reminder.sent_at);

export const getInvoiceSentReminders = ({ invoice }) => invoice?.reminders?.filter((reminder) => reminder.sent_at);

//same to isInvoiceOverdue util on the backend
export const isInvoiceOverdue = ({ invoice }) => {
  if (invoice?.sent_at && !invoice?.paid_at) {
    const dueDate = dayjs.utc(invoice?.date).add(invoice?.days_to_pay, 'day');
    return dayjs.utc().isSameOrAfter(dueDate, 'day');
  }
  return false;
};

export const hasInvoiceFailedToSave = ({ invoice }) => {
  const lastSaveInvoiceFailedAt = invoice?.metadata?.[INVOICE_MAGIC_METADATA.LAST_SAVE_INVOICE_FAILED_AT];
  return (
    !isNil(lastSaveInvoiceFailedAt) &&
    (isNil(invoice?.sent_at) || dayjs.utc(lastSaveInvoiceFailedAt).isAfter(invoice.sent_at))
  );
};

export const hasInvoiceFailedToSend = ({ invoice }) => {
  const lastSendEmailFailedAt = invoice?.metadata?.[INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT];
  return (
    !isNil(lastSendEmailFailedAt) &&
    (isNil(invoice?.sent_at) || dayjs.utc(lastSendEmailFailedAt).isAfter(invoice.sent_at))
  );
};

export const hasInvoicePaymentFailed = ({ invoice }) => invoice.auto_charge_payment_status === 'failed';

export const hasReminderFailedToSend = ({ reminder }) =>
  reminder?.metadata && (reminder?.metadata[REMINDER_ERROR_METADATA_FIELDS.LAST_SEND_EMAIL_FAILED_AT] ?? null) !== null;

export const hasAutoChargeSupport = ({ integrations }) =>
  integrations.some(({ service }) => getServiceCategory(service) === INTEGRATION_SERVICES.STRIPE);

export const displayBillingPeriod = ({ invoiceDate, frequency, language }) => {
  if (language) dayjs.locale(language);
  const monthLength = frequency ? INVOICING_FREQUENCY_TO_MONTH_LENGTH[frequency] : 0;
  const startDate = dayjs.utc(invoiceDate).format('MMM YYYY');
  return monthLength > 1
    ? `${startDate} - ${dayjs.utc(invoiceDate).add(monthLength, 'month').format('MMM YYYY')}`
    : startDate;
};

export const shouldSendEmailFromSubscript = ({ orgConfigs }) => {
  const { billingSenderDefaults } = orgConfigs;
  return billingSenderDefaults?.sender === SENDER_OPTIONS.SUBSCRIPT_EMAIL;
};

export const shouldSendEmailFromGmail = ({ orgConfigs }) => {
  const { billingSenderDefaults } = orgConfigs;
  return billingSenderDefaults?.sender_mailer === SENDER_MAILER_OPTIONS.GMAIL;
};

export const getConfigSenderEmail = ({ orgConfigs }) => {
  const { billingSenderDefaults } = orgConfigs;
  return billingSenderDefaults?.email_from;
};

export const getSuggestedFrequency = ({ transactions }) => {
  const allOptions = mapFilter(transactions, (transaction) =>
    normalizeFrequency(transaction.metadata?.[MAGIC_METADATA.BILLING_FREQUENCY]),
  );

  let chosenFrequency = allOptions[0];

  for (const option of allOptions) {
    if (INVOICING_FREQUENCY_TO_MONTH_LENGTH[option] < INVOICING_FREQUENCY_TO_MONTH_LENGTH[chosenFrequency]) {
      chosenFrequency = option;
    }
  }

  if (!chosenFrequency && transactions?.some(({ recognition }) => EVENT_RECOGNITION_TYPES.includes(recognition))) {
    return INVOICING_FREQUENCIES.EVENT_BASED;
  }

  return chosenFrequency;
};

// Should match auto charge cron job in backend
export const getAutoChargeJobRunsAt = () =>
  dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 03:00:00`, 'America/Los_Angeles').local();

// Should match smart dunning cron job in backend
export const getSmartDunningJobRunsAt = () =>
  dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 09:00:00`, 'America/Los_Angeles').local();

export const checkIfGmailDisconnected = ({ disauthenticatedIntegrations }) => {
  const disconnectedGmailAccount = getIntegrationByService({
    integrations: disauthenticatedIntegrations,
    service: INTEGRATION_SERVICES.GMAIL,
  });
  return !!disconnectedGmailAccount;
};

export const getInvoicesTotalAmount = ({ invoices }) =>
  invoices?.reduce(
    (scheduleTotal, invoiceAmount) =>
      scheduleTotal +
      invoiceAmount?.invoice_items.reduce(
        (invoiceTotal, invoiceItem) => invoiceTotal + (invoiceItem?.transaction_id ? invoiceItem?.amount ?? 0 : 0),
        0,
      ),
    0,
  );

export const isInvoiceMissingTaxes = ({ invoice, usesGLConfiguredTaxCodes }) => {
  if (!usesGLConfiguredTaxCodes) return false;
  if (!invoice.invoice_external_id) return false; // not yet saved to the external system - we never even polled yet
  const taxInvoiceItem = invoice.invoice_items?.find((item) => item.type === INVOICE_ITEM_TYPES.TAXES);
  const hasNoTax = !taxInvoiceItem || taxInvoiceItem.amount === 0;
  return hasNoTax;
};

export const isInvoiceStatusUnpaid = ({ isScheduleAutoCharge, invoice }) =>
  isScheduleAutoCharge &&
  dayjs.utc(invoice?.date).isBefore(dayjs.utc(), 'day') &&
  // there always should be metadata when schedule is auto-charge
  invoice?.metadata?.[INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE] === true;
