import React from 'react';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { NUMBER_FORMATS } from 'consts/global';
import { CardContents, Card, LabelContainer, MetricLabel, CardImage, MetricAmount } from './styled';

const MetricsCard = ({ historicalData, metric, activeMetric, endMonth, setActiveMetric }) => {
  const { key, label, icon } = metric;

  const numberFormatter = useCurrencyNumberFormatter();

  const onCardClick = () => {
    setActiveMetric(key);
  };

  return (
    <Card active={key === activeMetric} key={key} onClick={onCardClick} data-cy={`revenue_details__metric_card-${key}`}>
      <CardContents active={key === activeMetric}>
        <LabelContainer>
          <MetricLabel>{label}</MetricLabel>
          <div>
            <CardImage src={icon} alt={label} />
          </div>
        </LabelContainer>
        <MetricAmount>
          {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: historicalData[endMonth].revenue[key] })}
        </MetricAmount>
        <div>
          {historicalData[endMonth].customers[key]} <span>customers</span>
        </div>
      </CardContents>
    </Card>
  );
};

export default MetricsCard;
