import { FormikCustomSelector } from 'components/Controls';
import { SUPPORTED_INVOICE_LANGUAGES } from '../consts';

export const InvoicingScheduleLanguageSelector = () => (
  <FormikCustomSelector
    dataCy="custom-select--language"
    name="language"
    label="Language"
    placeholder="Select Language"
    options={Object.keys(SUPPORTED_INVOICE_LANGUAGES).map((lang) => ({
      value: lang,
      label: SUPPORTED_INVOICE_LANGUAGES[lang],
    }))}
  />
);
