import { useContext } from 'react';
import { CreditNoteContext } from '../../CreditNoteContext';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from '../../InvoicingScheduleTabsPanel';
import { InvoiceCreditNoteItem } from './InvoiceCreditNoteItem';

export const InvoiceCreditNotes = ({ creditNotes }) => {
  const { setSelectedTabsPanelTab } = useContext(InvoicingScheduleContext);
  const { setSelectedCreditNoteId, openConfirmUnallocateInvoiceModal } = useContext(CreditNoteContext);

  const handleUnlinkClick = async ({ creditNote }) => {
    openConfirmUnallocateInvoiceModal({ creditNoteId: creditNote.id });
  };

  const handleEditClick = ({ creditNote }) => {
    setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES);
    setSelectedCreditNoteId(creditNote.id);
  };

  return creditNotes.map((creditNote) => (
    <InvoiceCreditNoteItem creditNote={creditNote} onEditClick={handleEditClick} onUnlinkClick={handleUnlinkClick} />
  ));
};
