import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { useHistory } from 'react-router-dom';
import { CsvUpload } from 'shared/CsvUpload';
import { CUSTOMER_TYPES_OPTIONS } from 'shared/Filters';
import { cleanNumber } from 'utils/stringUtils';
import { useImportsAPI } from 'api/imports';
import { useCustomersAPI } from 'api/customers';
import {
  useUniqueUsageEventNamesAPI,
  useUniqueUsageEventSourcesAPI,
  useUsageEventsAPI,
} from 'api/usageBasedEngine/hooks';
import {
  findCustomerSubscriptId,
  getInitialIdSource,
  getOrgIdSources,
} from 'views/Transactions/TransactionsCsvUpload/utils';
import {
  INTERNAL_CUSTOMER_ID_SOURCE,
  NAME_MATCHING_SOURCE,
  SOURCE_TYPES,
} from 'views/Transactions/TransactionsCsvUpload/consts';
import { getColumns } from './columns';
import { getSchema } from './schema';
import { DEFAULT_CUSTOMER_MAPPER } from './consts';

export const UsageEventsCsvUpload = () => {
  const { organizations, orgId, integrations, orgConfigs } = useContext(AppContext);

  const history = useHistory();

  // `customerIdSourceAndType` has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };
  const [customerIdSourceAndType, setCustomerIdSourceAndType] = useState({});

  const { data: eventNameData, isLoading: isLoadingEvents } = useUniqueUsageEventNamesAPI({ orgId });
  const { data: eventSourceData, isLoading: isLoadingSources } = useUniqueUsageEventSourcesAPI({ orgId });

  const eventNames = useMemo(() => eventNameData?.data ?? [], [eventNameData?.data]);
  const eventSources = useMemo(() => eventSourceData?.data ?? [], [eventSourceData?.data]);

  const [submittingForm, setSubmittingForm] = useState(false);

  const { uploadCsvCustomersIdSource } = orgConfigs;

  const customersListParams = useMemo(
    () => ({
      limit: 30000,
      types: [CUSTOMER_TYPES_OPTIONS.LIVE, CUSTOMER_TYPES_OPTIONS.PROSPECT],
      // If matching internally by a metadata, we only need to fetch customers with existing id in metadata
      notNullMetadataField: [NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(customerIdSourceAndType.source)
        ? undefined
        : customerIdSourceAndType.source,
    }),
    [customerIdSourceAndType],
  );

  const {
    operations: { bulkUpload },
  } = useUsageEventsAPI({ autoFetch: false, orgId });

  const customersIdSources = useMemo(() => {
    const sources = {
      ...getOrgIdSources({
        integrations,
        internalSource: uploadCsvCustomersIdSource,
        suffix: 'customer ID',
      }),
      [INTERNAL_CUSTOMER_ID_SOURCE]: INTERNAL_CUSTOMER_ID_SOURCE,
    };

    sources[NAME_MATCHING_SOURCE] = 'Customer name';

    return sources;
  }, [integrations, uploadCsvCustomersIdSource]);

  useEffect(() => {
    if (organizations) {
      setCustomerIdSourceAndType(getInitialIdSource({ idSources: customersIdSources }));
    }
  }, [organizations, customersIdSources]);

  // With EXTERNAL source we get Customers from our imports table
  const {
    data: dataCustomerImports,
    isLoading: isCustomerImportsLoading,
    operations: { refetch: refetchCustomerImports },
  } = useImportsAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      providerName: customerIdSourceAndType.source,
      chifferObjectName: 'customer',
      includeCustomerName: true,
    },
    autoFetch: false,
  });

  // With INTERNAL source we get Customers from our customers table
  const {
    data: dataCustomers,
    isLoading: isCustomersLoading,
    operations: { refetch: refetchCustomers },
  } = useCustomersAPI({
    orgId: organizations?.[0]?.id,
    filters: { params: customersListParams },
    autoFetch: false,
  });

  // Fetch Customers
  useEffect(() => {
    if (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL) {
      refetchCustomers();
    } else {
      refetchCustomerImports();
    }
  }, [customerIdSourceAndType, refetchCustomers, refetchCustomerImports]);

  const customers = useMemo(
    () => (customerIdSourceAndType?.type === SOURCE_TYPES.INTERNAL ? dataCustomers : dataCustomerImports),
    [dataCustomers, dataCustomerImports, customerIdSourceAndType?.type],
  );

  const customersById = useMemo(
    () =>
      customers?.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [customers],
  );

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      defaultMapper,
      setFuzzyRowsMapper,
      formRef,
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,

        customers,
        customersById,
        customersIdSources,
        customerIdSourceAndType,
        setCustomerIdSourceAndType,
        uploadCsvCustomersIdSource,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,

        eventNames,
        eventSources,

        defaultMapper,
      }),
    [
      customers,
      customersById,
      customerIdSourceAndType,
      eventNames,
      eventSources,
      customersIdSources,
      uploadCsvCustomersIdSource,
      setCustomerIdSourceAndType,
    ],
  );

  const handleSubmit = async (values) => {
    setSubmittingForm(true);

    const createdEvents = await bulkUpload({
      data: values?.map((event) => ({
        ...event,
        customer_id: findCustomerSubscriptId({
          customerId: event.customer_id,
          customers,
          customerIdSourceAndType,
          uploadCsvCustomersIdSource,
        }),
      })),
    });

    setTimeout(() => {
      if (!!createdEvents?.length) {
        history.push('/billing/usage-based-engine?tab=usage-events');
      }

      setSubmittingForm(false);
    }, 1000);
  };

  const isLoading = submittingForm || isCustomerImportsLoading || isCustomersLoading;

  return (
    <CsvUpload
      onlyCreateMode={true}
      entityName="Usage-Events"
      backLink="/billing/usage-based-engine?tab=usage-events"
      isLoading={isLoading || isLoadingEvents || isLoadingSources}
      createSchema={getSchema({
        customers,
        customerIdSourceAndType,
        uploadCsvCustomersIdSource,
      })}
      handleSubmit={handleSubmit}
      csvColumnsTransformations={{ units: cleanNumber }}
      defaultMapperWithCreateOrUpdateMode={() => DEFAULT_CUSTOMER_MAPPER}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
    />
  );
};
