import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Centerer, Spacer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { ReactComponent as AlarmIcon } from 'images/alarm.svg';
import { Button } from 'components/Buttons';
import { ReactComponent as RightArrowIcon } from 'images/chevron-right.svg';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from '../consts';

const StyledAlarmIcon = styled(AlarmIcon)`
  width: 24px;
  height: 24px;
`;

const Text = styled.div`
  color: ${({ highlighted }) => (highlighted ? 'var(--primaryBlack70)' : 'var(--primaryBlack)')};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  font-size: ${({ fontSize }) => fontSize};
  display: inline-block;
  text-align: center;
`;

const StyledButton = styled(Button)`
  font-weight: 700;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  path {
    fill: var(--primaryGreen);
  }
`;

export const BulkSaveToGlModal = ({ onClose, invoiceIds }) => {
  const history = useHistory();

  return (
    <>
      <ModalContainer onClose={onClose}>
        <Modal maxWidth="500px" height="auto" background="var(--primaryGray)">
          <ModalCloseIcon onClose={onClose} />
          <ModalBody paddingBottom="20px">
            <Centerer direction="column">
              <StyledAlarmIcon />

              <Spacer height="12px" />

              <Text fontSize="14px" fontWeight="600">
                Subscript will save {invoiceIds?.length} invoices in your General Ledger.
              </Text>

              <Text fontSize="12px" highlighted>
                Please be aware that responses from third-party services might take some time. You can check the status
                of your invoices at a later time.
              </Text>

              <Spacer height="12px" />

              <StyledButton
                onClick={() => {
                  history.push({
                    pathname: BILLING_PAGES_ROUTES.INVOICES,
                    state: { status: INVOICE_STATUSES.PROCESSING },
                  });
                  onClose();
                }}
              >
                View invoices in processing
                <StyledRightArrowIcon />
              </StyledButton>
            </Centerer>
          </ModalBody>
        </Modal>
      </ModalContainer>
    </>
  );
};

export const useBulkSaveToGlModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [invoiceIds, setInvoiceIds] = useState([]);

  const openModal = useCallback(({ invoiceIds }) => {
    setInvoiceIds(invoiceIds ?? []);
    setShowModal(true);
  }, []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const Modal = useCallback(
    () => (showModal ? <BulkSaveToGlModal onClose={closeModal} invoiceIds={invoiceIds} /> : null),
    [showModal, closeModal, invoiceIds],
  );

  return {
    openModal,
    Modal,
    isModalVisible: showModal,
  };
};
