import React, { useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS, RECURRING_RECOGNITION_TYPES } from 'consts/global';
import { Modal, ModalContainer, ModalBody, ModalCloseIcon, ModalHeader, ModalTitle } from 'components/Modal';
import { TRANSACTION_TABLE_COLUMN_KEY } from 'shared/TransactionTable';
import { TransactionsPage } from 'views/Transactions/TransactionsPage';
import { numberFormatter } from 'utils/formatters';

const Total = styled.span`
  margin-left: 12px;
  color: var(--primaryGreen);
`;

export const BookingsModal = ({ closeModal, bookingStartDate, bookingEndDate, bookingsValue }) => {
  const {
    metadataFilter,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const [hiddenTransactionColumns, setHiddenTransactionColumns] = useState([
    TRANSACTION_TABLE_COLUMN_KEY.ID,
    TRANSACTION_TABLE_COLUMN_KEY.SEATS,
    TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION,
    TRANSACTION_TABLE_COLUMN_KEY.LEFT_TO_RECOGNIZE,
    TRANSACTION_TABLE_COLUMN_KEY.UPDATED_AT,
    TRANSACTION_TABLE_COLUMN_KEY.CREATED_AT,
  ]);

  const extendedMetadataFilter = useMemo(() => {
    return {
      ...metadataFilter,
      transactionMetadata: [
        ...(metadataFilter?.transactionMetadata ?? []),
        {
          topLevel__date: dayjs(bookingStartDate).endOf('day').subtract(1, 'day').toISOString(),
          _operator: '>',
        },
        { topLevel__date: dayjs(bookingEndDate).startOf('day').toISOString(), _operator: '<' },
        { topLevel__recognition: RECURRING_RECOGNITION_TYPES, _operator: 'in' },
      ],
    };
  }, [bookingStartDate, bookingEndDate, metadataFilter]);

  //end date is not inclusive
  const stringStartDate = dayjs(bookingStartDate).format('MMMM YYYY');
  const stringEndDate = dayjs(bookingEndDate).subtract(1, 'day').format('MMMM YYYY');

  const timeframe = stringStartDate === stringEndDate ? stringStartDate : `${stringStartDate} - ${stringEndDate}`;

  return (
    <ModalContainer>
      <Modal width="90%" padding="50px" height="90vh" overflow="visible">
        <ModalHeader>
          <ModalCloseIcon style={{ right: 20 }} onClose={() => closeModal()} />
        </ModalHeader>
        <ModalBody paddingLeft="20px" paddingRight="20px">
          <TransactionsPage
            headerLeftContent={
              <ModalTitle padding="0 0 0 0">
                <b>
                  Bookings for {timeframe}:
                  <Total>
                    {bookingsValue === null
                      ? 'Calculating...'
                      : numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: bookingsValue,
                          currency: currencyISOCode,
                        })}
                  </Total>
                </b>
              </ModalTitle>
            }
            extendedMetadataFilter={extendedMetadataFilter}
            transactionsConfirmation="Confirmed"
            transactionsGroupBy="Customer"
            hiddenTransactionColumns={hiddenTransactionColumns}
            setHiddenTransactionColumns={setHiddenTransactionColumns}
            showTransactionBanners={false}
            showingBookingsTransactions={true}
          />
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};
