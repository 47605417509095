import React, { useContext } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS, RECOGNITION_TYPES } from 'consts/global';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { CentererVertical, Column, Flexer, Row } from 'components/Core';
import { TrashIcon, WarningIcon } from 'components/Icons';
import { ReactComponent as ExternalLink } from 'images/external-link-billing.svg';
import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { PRODUCT_IMPORT_METADATA_KEYS, SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { CircleCheckbox, CircleCheckboxContent } from 'views/Wizard/styles';
import { transactionDisplayTitle } from 'models/transaction';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { getTransactionAmountByRecognition, getDefaultBillingOption } from '../utils';
import { INVOICE_ADDITION_OPTIONS, BILLING_ADVANCE_ARREARS_OPTIONS } from '../consts';

const Wrapper = styled(Flexer)`
  font-size: 12px;
  flex-direction: column;
  line-height: 16px;
  width: 100%;
`;

const TransactionName = styled.div`
  font-weight: 700;
`;

const CheckboxesColumn = styled(Column)`
  justify-content: flex-start;
  padding: 12px;
  align-items: flex-start;
  background-color: ${({ isActive }) => (isActive ? 'white' : 'transaparent')};
  box-shadow: ${({ isActive }) => isActive && '8px 8px 60px 0px var(--primaryBlack4)'};
  height: 100%;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  b {
    white-space: nowrap;
    font-weight: ${({ isActive }) => !isActive && 'normal'};
  }
`;

const TransactionAmount = styled.div`
  font-weight: 400;
  font-size: 12px;
  width: 100px;
  text-align: end;
  line-height: 16px;
`;

const TransactionTitleRow = styled(Row)`
  justify-content: space-between;
`;

const AdditionalCheckboxes = styled(Row)`
  margin-top: 8px;
  border: 1px solid var(--accentGraySecond);
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  height: 106px;

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(0.8);
  }

  .ant-checkbox-wrapper {
    position: relative;
    margin-right: 6px;
    top: 1px;
    left: -2px;
  }
`;

const TransactionDates = styled.div`
  color: var(--primaryBlack50);
  width: 150px;
  white-space: nowrap;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 24px;
  margin-top: 5px;
  opacity: ${({ isDisabled }) => isDisabled && '0.2'};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};

  &:first-child {
    margin-top: 0;
  }

  svg:hover {
    g {
      opacity: 1;
    }
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const AddIncomeAccount = styled.span`
  cursor: pointer;
  color: var(--primaryBlue);
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
`;

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 5px;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const SELECT_TRANSACTIONS_TABLE_LIST_MODE = {
  SELECTABLE: 'selectable',
  DELETABLE: 'deletable',
  VIEW: 'view',
};

export const TransactionLineItem = ({
  showCheckbox = false,
  transaction,
  disabled,
  invoicingScheduleFrequency,
  listMode = SELECT_TRANSACTIONS_TABLE_LIST_MODE.SELECTABLE,
  showOptions,
}) => {
  const {
    integrations,
    orgConfigs,
    appSettings: { currencyISOCode },
  } = useContext(AppContext);
  const {
    handleUpdateBulkIncomeAccount,
    productImportsByProductId,
    scheduleFormRef,
    setIncludedTransactions,
  } = useContext(InvoicingScheduleContext);

  const { values, setFieldValue } = scheduleFormRef?.current ?? {};

  const isCreateMode = !values?.id;

  const invoicingService = getServiceCategory(integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service);

  const { incomeAccountRefId, incomeAccountOptions = {} } = orgConfigs;

  const numberFormatter = useCurrencyNumberFormatter({ currency: transaction?.currency ?? currencyISOCode });

  const selected = Object.keys(values?.transaction_options ?? {})?.includes(transaction.id);

  let displayDate = dayjs.utc(transaction.start_date).format('MMM DD, YYYY');
  if (transaction.end_date && dayjs(transaction.end_date).isValid()) {
    displayDate += ' - ' + dayjs.utc(transaction.end_date).format('MMM DD, YYYY');
  }

  if (transaction.replaced_by) {
    displayDate += ' (transaction is replaced)';
  }

  const serviceWithIncomeAccount = SERVICE_WITH_INCOME_ACCOUNT_REF.includes(invoicingService);
  const incomeAccount =
    productImportsByProductId[transaction?.product_id]?.metadata?.[PRODUCT_IMPORT_METADATA_KEYS.INCOME_ACCOUNT] ??
    incomeAccountRefId;

  const handleTransactionSelection = () => {
    if (selected) {
      const currentValues = { ...values.transaction_options };
      delete currentValues[transaction.id];
      setFieldValue('transaction_options', currentValues);
    } else {
      const defaultBillingOption = getDefaultBillingOption(transaction.recognition);
      setFieldValue('transaction_options', {
        ...values.transaction_options,
        [transaction.id]: {
          addType: INVOICE_ADDITION_OPTIONS.MERGE,
          prorateFirstInvoice: true,
          onlyFutureInvoices: false,
          allowPastInvoices: true,
          billingAdvanceArrearsOption: defaultBillingOption,
        },
      });
    }
  };

  return (
    <Wrapper>
      <TransactionTitleRow>
        <Row horizontal="flex-start" style={{ width: '250px' }}>
          {showCheckbox && listMode === SELECT_TRANSACTIONS_TABLE_LIST_MODE.SELECTABLE && (
            <Checkbox
              checked={selected}
              disabled={disabled}
              onClick={handleTransactionSelection}
              data-cy={`select-transaction-modal__checkbox--${transaction.name}`}
              style={{
                marginTop: '5px',
                marginRight: '8px',
              }}
            />
          )}
          {listMode === SELECT_TRANSACTIONS_TABLE_LIST_MODE.DELETABLE && isCreateMode && (
            <StyledTrashIcon
              size="16px"
              onClick={() => {
                handleTransactionSelection();
                setIncludedTransactions((prev) => prev.filter((t) => t.id !== transaction.id));
              }}
            />
          )}
          <TransactionName disabled={disabled}>
            <TruncateStringWithTooltip length={25} tooltipWidth={200}>
              {transactionDisplayTitle(transaction)}
            </TruncateStringWithTooltip>
          </TransactionName>

          {incomeAccount && incomeAccount !== '-1' && (
            <TooltipContainer
              fontSize="12px"
              toolTipContent={`Income account: ${incomeAccountOptions[incomeAccount]} / ${incomeAccount}`}
              width={400}
            >
              <QuestionIcon style={{ marginLeft: 5, marginTop: 5 }} />
            </TooltipContainer>
          )}

          <StyledExternalLink
            onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}
            style={{
              marginLeft: '5px',
              alignSelf: 'center',
            }}
          />
        </Row>

        <TransactionDates>{displayDate}</TransactionDates>

        <TransactionAmount>
          {numberFormatter({
            type: NUMBER_FORMATS.CURRENCY,
            rawValue: getTransactionAmountByRecognition({ transaction }),
          })}
        </TransactionAmount>
      </TransactionTitleRow>

      {serviceWithIncomeAccount && !incomeAccount && !showCheckbox && (
        <CentererVertical gap="8px">
          <WarningIcon size="12px" />
          <AddIncomeAccount
            onClick={() => {
              handleUpdateBulkIncomeAccount([
                {
                  id: transaction.product_id,
                  name: transaction.product_name,
                },
              ]);
            }}
          >
            Set the income account
          </AddIncomeAccount>
        </CentererVertical>
      )}

      {selected && !disabled && showOptions && (
        <>
          <CheckboxRow>
            <CircleCheckbox
              checked={
                values?.transaction_options?.[transaction?.id]?.billingAdvanceArrearsOption ===
                BILLING_ADVANCE_ARREARS_OPTIONS.IN_ADVANCE
              }
              data-cy={`select-transaction-modal__transaction-${transaction?.id}--advance`}
              width="16px"
              height="16px"
              borderWidth={5}
              onClick={() => {
                setFieldValue('transaction_options', {
                  ...values.transaction_options,
                  [transaction.id]: {
                    ...values.transaction_options?.[transaction.id],
                    billingAdvanceArrearsOption: BILLING_ADVANCE_ARREARS_OPTIONS.IN_ADVANCE,
                  },
                });
              }}
            />
            <CircleCheckboxContent>Bill in advance</CircleCheckboxContent>
            <CircleCheckbox
              checked={
                values?.transaction_options?.[transaction?.id]?.billingAdvanceArrearsOption ===
                BILLING_ADVANCE_ARREARS_OPTIONS.IN_ARREARS
              }
              data-cy={`select-transaction-modal__transaction-${transaction?.id}--arrears`}
              width="16px"
              height="16px"
              borderWidth={5}
              style={{ marginLeft: '16px' }}
              onClick={() =>
                setFieldValue('transaction_options', {
                  ...values.transaction_options,
                  [transaction.id]: {
                    ...values.transaction_options?.[transaction.id],
                    billingAdvanceArrearsOption: BILLING_ADVANCE_ARREARS_OPTIONS.IN_ARREARS,
                  },
                })
              }
            />
            <CircleCheckboxContent>Bill in arrears</CircleCheckboxContent>
          </CheckboxRow>
          <AdditionalCheckboxes>
            <CheckboxesColumn
              isActive={values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.ADD_NEW}
            >
              <CheckboxRow
                onClick={() =>
                  setFieldValue('transaction_options', {
                    ...values.transaction_options,
                    [transaction.id]: {
                      ...values.transaction_options?.[transaction.id],
                      addType: INVOICE_ADDITION_OPTIONS.ADD_NEW,
                      prorateFirstInvoice: false,
                      onlyFutureInvoices: false,
                      allowPastInvoices: true,
                    },
                  })
                }
              >
                <CircleCheckbox
                  data-cy={`select-transaction-modal__transaction-${transaction?.id}--new`}
                  checked={
                    values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.ADD_NEW ||
                    values?.transaction_options?.[transaction?.id]?.addType !== INVOICE_ADDITION_OPTIONS.MERGE
                  }
                  width="16px"
                  height="16px"
                  borderWidth={5}
                />
                <b>
                  Generate {invoicingScheduleFrequency?.toLowerCase() ?? 'monthly'} invoices based on the dates of this
                  transaction
                </b>
              </CheckboxRow>

              <CheckboxRow
                data-cy={`select-transaction-modal__transaction-${transaction?.id}--only-future`}
                isDisabled={
                  values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE ||
                  !values?.transaction_options?.[transaction?.id]?.allowPastInvoices
                }
                onClick={() =>
                  setFieldValue('transaction_options', {
                    ...values.transaction_options,
                    [transaction.id]: {
                      ...values.transaction_options?.[transaction.id],
                      onlyFutureInvoices: !values.transaction_options?.[transaction.id]?.onlyFutureInvoices,
                    },
                  })
                }
              >
                <Checkbox
                  checked={!!values?.transaction_options?.[transaction?.id]?.onlyFutureInvoices}
                  isDisabled={
                    values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE ||
                    !values?.transaction_options?.[transaction?.id]?.allowPastInvoices
                  }
                />
                Create only future invoices
                <TooltipContainer
                  fontSize="12px"
                  toolTipContent={<>Only create invoices that have dates after today</>}
                  width={230}
                >
                  <QuestionIcon style={{ marginLeft: 5, marginTop: 4 }} />
                </TooltipContainer>
              </CheckboxRow>

              <CheckboxRow
                data-cy={`select-transaction-modal__transaction-${transaction?.id}--past-for-today`}
                isDisabled={
                  values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE ||
                  values?.transaction_options?.[transaction?.id]?.onlyFutureInvoices
                }
                onClick={() =>
                  setFieldValue('transaction_options', {
                    ...values.transaction_options,
                    [transaction.id]: {
                      ...values.transaction_options?.[transaction.id],
                      allowPastInvoices: !values.transaction_options?.[transaction.id]?.allowPastInvoices,
                    },
                  })
                }
              >
                <Checkbox
                  disabled={
                    values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE ||
                    values?.transaction_options?.[transaction?.id]?.onlyFutureInvoices
                  }
                  checked={!values?.transaction_options?.[transaction?.id]?.allowPastInvoices}
                />
                Date past invoices for today{' '}
                <TooltipContainer
                  fontSize="12px"
                  toolTipContent={<>If invoices have dates in the past, we'll set them to today's date</>}
                  width={230}
                >
                  <QuestionIcon style={{ marginLeft: 5, marginTop: 4 }} />
                </TooltipContainer>
              </CheckboxRow>
            </CheckboxesColumn>

            <CheckboxesColumn
              isActive={values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE}
            >
              <CheckboxRow
                onClick={() =>
                  setFieldValue('transaction_options', {
                    ...values.transaction_options,
                    [transaction.id]: {
                      ...values.transaction_options?.[transaction.id],
                      addType: INVOICE_ADDITION_OPTIONS.MERGE,
                      prorateFirstInvoice: true,
                      onlyFutureInvoices: false,
                      allowPastInvoices: true,
                    },
                  })
                }
              >
                <CircleCheckbox
                  checked={values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.MERGE}
                  width="16px"
                  height="16px"
                  borderWidth={5}
                />
                <b>Combine with existing invoices, where possible </b>
                <TooltipContainer
                  fontSize="12px"
                  toolTipContent={
                    <>
                      We'll add this transaction as a line item to invoices that will be generated based on{' '}
                      <b>dates of other transactions</b> in the schedule
                    </>
                  }
                  width={230}
                >
                  <QuestionIcon style={{ marginLeft: 5, marginTop: 4 }} />
                </TooltipContainer>
              </CheckboxRow>

              <CheckboxRow
                isDisabled={
                  ![
                    RECOGNITION_TYPES.tillCanceled,
                    RECOGNITION_TYPES.linear,
                    RECOGNITION_TYPES.linearNotRecurring,
                  ].includes(transaction?.recognition) ||
                  values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.ADD_NEW
                }
                onClick={() =>
                  setFieldValue('transaction_options', {
                    ...values.transaction_options,
                    [transaction.id]: {
                      ...values.transaction_options?.[transaction.id],
                      prorateFirstInvoice: !values.transaction_options?.[transaction.id]?.prorateFirstInvoice,
                    },
                  })
                }
              >
                <Checkbox
                  disabled={
                    values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.ADD_NEW
                  }
                  checked={
                    values?.transaction_options?.[transaction?.id]?.addType === INVOICE_ADDITION_OPTIONS.ADD_NEW
                      ? false
                      : !!values?.transaction_options?.[transaction?.id]?.prorateFirstInvoice
                  }
                />
                Also, prorate the 1st invoice that will have this transaction
              </CheckboxRow>
            </CheckboxesColumn>
          </AdditionalCheckboxes>
        </>
      )}
    </Wrapper>
  );
};
