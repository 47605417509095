import { reFormatDate } from 'utils/dateUtils';

export const getColumnValue = (header, data, monthDataKey, summaryObject, summaryDataKey) => {
  if (summaryObject?.[header]) {
    return summaryObject[header][summaryDataKey];
  } else {
    const monthDateKey = reFormatDate(header, 'MMM YYYY', 'YYYY-MM');
    if (data[monthDateKey]) {
      return data[monthDateKey][monthDataKey];
    }
  }
};
