import React, { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { Popover, PopoverButton, PopoverPrompt, PopoverActions } from 'components/Portal';
import { FlexerRow } from 'components/Core';
import { CircleCheckbox } from 'views/Wizard/styles';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { BillingContext } from '../BillingContext';
import { updateDateFromDatePicker } from 'utils/dateUtils';

const OptionsWrapper = styled(FlexerRow)`
  border-radius: 4px;
  margin: 0 8px 8px 8px;
  color: white;
`;

const OptionWrapper = styled(FlexerRow)`
  padding: 8px;
`;

const Checkbox = styled(CircleCheckbox)`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 50%;
  flex-grow: 100;
  margin-left: 5px;
  ${({ checked }) =>
    checked
      ? css`
          border: 5px solid var(--primaryBlue);
          background: white;
        `
      : css`
          border: 1px solid var(--accentGrayThird);
          background: var(--dark10);
        `};
`;

const OptionLabel = styled(PopoverPrompt)`
  opacity: 1;
  padding: 0;
`;

const MOVE_DATE_OPTIONS = {
  NONE: 'none',
  LAST_DAY: 'lastDay',
  FIXED_DAY: 'fixedDay',
};

const CHANGE_AUTO_SEND_OPTIONS = {
  NO: 'no',
  THIS_INVOICE: 'thisInvoice',
  ALL: 'all',
};

export const useDateChangePopovers = ({
  invoice,
  invoices,
  scheduleFormValues,
  invoiceFormValues,
  index,
  setFieldValue,
  moveDatesPopoverXOffset,
  moveDatesPopoverYOffset,
  shouldSaveOnNo = true,
}) => {
  const { invoiceFormRef, scheduleFormRef } = useContext(InvoicingScheduleContext);

  const { editInvoice, bulkEditInvoices } = useContext(BillingContext);

  const [showPopover, setShowPopover] = useState(false);
  const [showMoveToLastDayOption, setShowMoveToLastDayOption] = useState(false);
  const openPopover = () => setShowPopover(true);
  const closePopover = () => setShowPopover(false);

  const [invoicesToChange, setInvoicesToChange] = useState([]);
  const [monthDay, setMonthDay] = useState();
  const [changeInMonths, setChangeInMonths] = useState();
  const [oldIndex, setOldIndex] = useState(null);

  const [changeAutoSendDates, setChangeAutoSendDates] = useState(CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE);
  const [updateOtherInvoiceDates, setUpdateOtherInvoiceDates] = useState(MOVE_DATE_OPTIONS.NONE);

  useEffect(() => {
    if (updateOtherInvoiceDates !== MOVE_DATE_OPTIONS.NONE)
      // probably should change auto send dates too
      setChangeAutoSendDates(CHANGE_AUTO_SEND_OPTIONS.ALL);
    else if (updateOtherInvoiceDates === MOVE_DATE_OPTIONS.NONE)
      setChangeAutoSendDates(CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE);
  }, [updateOtherInvoiceDates]);

  const doSomeInvoicesHaveAutoSend = invoices.some((i) => i.auto_send);

  const checkIfShouldMoveToLastDay = ({ currentInvoice, invoices }) => {
    if (invoices?.length > 1) {
      // do not need to handle if selected date is 31st
      const selectedDateIsLastDayOfMonth =
        dayjs.utc(currentInvoice.date).date() !== 31 &&
        dayjs.utc(currentInvoice.date).isSame(dayjs.utc(invoices[0].date).endOf('month'), 'day');

      const generatedInvoicesAreNotLastDayOfMonth = invoices.some((invoice) => {
        return !dayjs.utc(invoice.date).isSame(dayjs.utc(invoice.date).endOf('month'), 'day');
      });

      return selectedDateIsLastDayOfMonth && generatedInvoicesAreNotLastDayOfMonth;
    }
  };

  const changeFollowingInvoiceDates = useCallback(
    async ({ changeInvoiceAutoSendDates = false, lastDayOfMonth = false }) => {
      const newInvoices = [];
      const invoicesToBulkEdit = [];
      for (const invoice of invoices) {
        if (invoicesToChange.includes(invoice.id ?? invoice.unsavedId) && !invoice.sent_at) {
          let newDate;
          if (lastDayOfMonth) {
            newDate = dayjs.utc(invoice.date).endOf('month').toDate();
          } else {
            const isValidMonthDay =
              dayjs.utc(invoice.date).add(changeInMonths, 'months').endOf('month').get('date') >= monthDay;

            newDate = isValidMonthDay
              ? dayjs.utc(invoice.date).add(changeInMonths, 'months').set('date', monthDay).toDate()
              : dayjs.utc(invoice.date).add(changeInMonths, 'months').endOf('month').toDate();
          }
          const newInvoice = {
            ...invoice,
            date: newDate,
            send_date: changeInvoiceAutoSendDates ? newDate : undefined,
          };
          newInvoices.push(newInvoice);
          // Save the changes if the invoice exists
          if (invoice.id) {
            invoicesToBulkEdit.push(newInvoice);
          }
        } else {
          newInvoices.push(invoice);
        }
      }

      scheduleFormRef?.current?.setFieldValue('invoices', newInvoices);

      invoicesToBulkEdit.length && (await bulkEditInvoices({ data: invoicesToBulkEdit }));
      closePopover(false);
    },
    [invoices, scheduleFormRef, invoicesToChange, changeInMonths, monthDay, bulkEditInvoices],
  );

  const onDateChange = async (name, date) => {
    if (!date) return null;

    setShowMoveToLastDayOption(
      checkIfShouldMoveToLastDay({
        currentInvoice: invoice,
        invoices,
      }),
    );
    if (index !== invoices.length - 1) {
      // the difference between the old date value and the new one
      const toChange = [];
      for (const [invoiceIndex, invoice] of Object.entries(invoices)) {
        if (parseInt(invoiceIndex) > index) toChange.push(invoice.id ?? invoice.unsavedId);
      }
      const dayjsNewDate = dayjs(date);
      const dayjsOldDate = dayjs.utc(invoice.date);
      const monthDifference = Math.round(
        dayjsNewDate.startOf('month').diff(dayjsOldDate.startOf('month'), 'month', true),
      ); // positive if moving forward in months and negative if moving boackwards

      const monthDay = dayjsNewDate.get('date');
      const newDate = dayjsNewDate.utc(true).toDate();
      const shouldshowMoveToLastDayOption =
        index === 0 &&
        checkIfShouldMoveToLastDay({
          invoices: invoices.map((invoice, i) =>
            i === 0
              ? {
                  ...invoice,
                  date: newDate,
                }
              : invoice,
          ),
          currentInvoice: {
            ...invoice,
            date: newDate,
          },
        });

      setInvoicesToChange(toChange);
      setChangeInMonths(monthDifference);
      setMonthDay(monthDay);
      setOldIndex(index); // because this will change if date is changed, and popover will get confused
      setShowMoveToLastDayOption(shouldshowMoveToLastDayOption);
      openPopover();
    }
    const newDateForField = updateDateFromDatePicker(date);
    setFieldValue(name, newDateForField);
    // Sync the date in the invoice form
    invoiceFormRef?.current?.setFieldValue('date', newDateForField);
  };

  const handlePopoverSubmit = async () => {
    closePopover();

    const date = scheduleFormValues ? scheduleFormValues?.invoices?.[oldIndex ?? index]?.date : invoiceFormValues?.date;
    switch (updateOtherInvoiceDates) {
      case MOVE_DATE_OPTIONS.FIXED_DAY:
      case MOVE_DATE_OPTIONS.LAST_DAY:
        invoice?.id &&
          (await editInvoice({
            id: invoice?.id,
            data: {
              date,
              send_date: [CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE, CHANGE_AUTO_SEND_OPTIONS.ALL].includes(
                changeAutoSendDates,
              )
                ? date
                : undefined,
            },
          }));
        changeFollowingInvoiceDates({
          changeInvoiceAutoSendDates: changeAutoSendDates === CHANGE_AUTO_SEND_OPTIONS.ALL,
          lastDayOfMonth: updateOtherInvoiceDates === MOVE_DATE_OPTIONS.LAST_DAY,
        });
        setOldIndex(null);

        break;
      case MOVE_DATE_OPTIONS.NONE:
        invoice?.id &&
          shouldSaveOnNo &&
          (await editInvoice({
            id: invoice?.id,
            data: {
              date,
              send_date: changeAutoSendDates === CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE ? date : undefined,
            },
          }));
        setOldIndex(null);
        break;

      default:
        break;
    }
  };

  const moveDatesOptions = [
    { label: 'No', value: MOVE_DATE_OPTIONS.NONE },
    {
      label: `${changeInMonths !== 0 ? `Mo. by ${changeInMonths} and` : ''} Date to ${monthDay}`,
      value: MOVE_DATE_OPTIONS.FIXED_DAY,
    },
  ];
  if (showMoveToLastDayOption)
    moveDatesOptions.push({ label: 'Last day of the month', value: MOVE_DATE_OPTIONS.LAST_DAY });

  const setAutoSendOptions = [{ label: 'No', value: CHANGE_AUTO_SEND_OPTIONS.NO }];
  if (invoice?.auto_send)
    setAutoSendOptions.push({ label: 'Yes, only for this invoice', value: CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE });
  if (updateOtherInvoiceDates !== MOVE_DATE_OPTIONS.NONE && doSomeInvoicesHaveAutoSend)
    setAutoSendOptions.push({ label: 'Yes, for all invoices', value: CHANGE_AUTO_SEND_OPTIONS.ALL });

  const moveDatesPopover = showPopover && (
    <Popover
      darkMode
      width="330px"
      data-cy="invoicing-schedule__change-future-dates-portal"
      XOffset={moveDatesPopoverXOffset ?? 160}
      YOffset={moveDatesPopoverYOffset ?? 30}
    >
      <PopoverPrompt>Do you want to move the dates of subsequent invoices?</PopoverPrompt>

      <OptionsWrapper>
        {moveDatesOptions.map(({ label, value }, index) => (
          <OptionWrapper
            key={value}
            position={index === 0 ? 'start' : index === moveDatesOptions.length - 1 ? 'end' : 'middle'}
          >
            <Checkbox
              checked={value === updateOtherInvoiceDates}
              onClick={() => {
                setUpdateOtherInvoiceDates(value);
              }}
              data-cy={`invoicing-schedule__change-future-dates-portal__option-${value}-checkbox`}
            />
            <OptionLabel>{label}</OptionLabel>
          </OptionWrapper>
        ))}
      </OptionsWrapper>

      {setAutoSendOptions.length > 1 && (
        <>
          <PopoverPrompt>Do you also want to change the auto-send date?</PopoverPrompt>

          <OptionsWrapper>
            {setAutoSendOptions.map(({ label, value }, index) => (
              <OptionWrapper
                key={value}
                position={index === 0 ? 'start' : index === setAutoSendOptions.length - 1 ? 'end' : 'middle'}
              >
                <Checkbox
                  checked={value === changeAutoSendDates}
                  onClick={() => {
                    setChangeAutoSendDates(value);
                  }}
                  data-cy={`invoicing-schedule__change-future-dates-portal__auto-send-option-${value}-checkbox`}
                />
                <OptionLabel>{label}</OptionLabel>
              </OptionWrapper>
            ))}
          </OptionsWrapper>
        </>
      )}

      <PopoverActions>
        <PopoverButton onClick={closePopover} data-cy={'invoicing-schedule__dates-popover__dismiss'}>
          Dismiss
        </PopoverButton>
        <PopoverButton onClick={handlePopoverSubmit} data-cy={'invoicing-schedule__dates-popover__save'} primary>
          Save change
        </PopoverButton>
      </PopoverActions>
    </Popover>
  );

  return { moveDatesPopover, onDateChange };
};
