import axios from 'axios';
import { API_URL } from 'api/consts';

export const getCSVImports = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/csv-imports`, { params });
  return response.data;
};

export const getCSVImportById = async ({ orgId, csvImportId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/csv-imports/${csvImportId}`, { params });
  return response.data;
};

export const createCSVImport = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/csv-imports`, body);
  return response.data;
};

export const deleteCSVImport = async ({ orgId, csvImportId }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/csv-imports/${csvImportId}`);
  return response.data;
};
