import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Header, HeaderTitle } from 'shared/Layout';
import { FlexerRow } from 'components/Core';
import { IconButton } from 'components/Buttons';
import { PlusSignIcon } from 'components/Icons';

export const ContractsListHeader = () => {
  const history = useHistory();
  return (
    <Header
      headerLeft={<HeaderTitle>Contracts</HeaderTitle>}
      headerRight={
        <FlexerRow justifyContent="end" gap="12px">
          <IconButton
            data-cy="contracts-page__add-contract"
            icon={<PlusSignIcon />}
            iconFillColor="var(--primaryGreen)"
            onClick={() => history.push('/contracts/pdfs')}
          >
            Contracts
          </IconButton>
        </FlexerRow>
      }
    />
  );
};
