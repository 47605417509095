import { assertCloseTo } from 'utils/assertionUtils';

export const checkSumOfSegments = ({ organization, user, data }) => {
  if (!data.breakdownRowsByCustomer) return;

  const totalOfSegmentsByMonth = Object.values(data.breakdownRowsByCustomer ?? {}).reduce((acc, byMonth) => {
    for (const [month, bySegment] of Object.entries(byMonth)) {
      for (const amount of Object.values(bySegment)) {
        acc[month] = (acc[month] ?? 0) + amount;
      }
    }
    return acc;
  }, {});

  for (const [month, totalAmount] of Object.entries(data.totalAmountsEachMonth)) {
    const assertionPassed = assertCloseTo({
      organization,
      user,
      context: 'Spreads',
      expected: totalAmount,
      actual: totalOfSegmentsByMonth[month] ?? 0,
      description: `Total amount for ${month} as a sum of segments`,
    });

    if (!assertionPassed) return;
  }
};

export const checkAverageAndSumRows = ({ organization, user, data }) => {
  const customersByMonth = {};
  const totalByMonth = {};
  const countWithZeroByMonth = {};

  for (const byMonth of Object.values(data.revenueRowsByCustomer)) {
    for (const [month, amount] of Object.entries(byMonth)) {
      // Averages are calculated differently in the cohort vs non-cohort case
      if (amount) customersByMonth[month] = (customersByMonth[month] ?? 0) + 1;
      countWithZeroByMonth[month] = (countWithZeroByMonth[month] ?? 0) + 1;
      totalByMonth[month] = (totalByMonth[month] ?? 0) + amount;
    }
  }

  for (const month of Object.keys(data.totalAmountsEachMonth)) {
    let assertionPassed = true;

    assertionPassed =
      assertionPassed &&
      assertCloseTo({
        organization,
        user,
        context: 'Spreads',
        expected: totalByMonth[month] || 0,
        actual: data.totalAmountsEachMonth[month] || 0,
        description: `Total amount for ${month} as sum of customers`,
      });

    // This is from the Revenue Spreads page
    if (data.avgAmountEachMonth[month]) {
      assertionPassed =
        assertionPassed &&
        assertCloseTo({
          organization,
          user,
          context: 'Spreads',
          expected: data.avgAmountEachMonth[month] || 0,
          actual: (totalByMonth[month] || 0) / (customersByMonth[month] || 1),
          description: `Average amount for ${month} as sum of nonzero customers`,
        });
    }

    // This is from the cohorts drilldown
    if (data.avgAmountEachMonthWithZeros[month]) {
      assertionPassed =
        assertionPassed &&
        assertCloseTo({
          organization,
          user,
          context: 'Spreads',
          expected: data.avgAmountEachMonthWithZeros[month] || 0,
          actual: (totalByMonth[month] || 0) / (countWithZeroByMonth[month] || 1),
          description: `Average amount for ${month} as sum of all customers`,
        });
    }

    if (!assertionPassed) return;
  }
};
