import styled from 'styled-components';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { numberFormatter } from 'utils/formatters';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Link } from 'react-router-dom';

import { Row } from 'components/Core';
import { ReactComponent as GreyDot } from 'images/grey-dot.svg';
import { ReactComponent as ArrowUp } from 'images/arrow_up_bold.svg';
import { ReactComponent as DangerIcon } from 'images/icon_danger.svg';
import { NUMBER_FORMATS } from 'consts/global';
import { StyledTableName, StyledTableNameTitle, TableSubRowsCount, TableSubRowsCountArrow } from 'components/Table';
import { TruncateStringWithTooltip } from 'components/Tooltip';
import { getCustomerDisplayName } from 'models/customer';
import { getTransactionFieldValue } from 'shared/TransactionTable/utils';
import { TRANSACTION_FIELD_VALUE_BY_ID, TRANSACTION_TABLE_COLUMN_KEY } from 'shared/TransactionTable';
import { TransactionRecognitionIcon } from 'shared/Icons';

dayjs.extend(isSameOrBefore);

const MRRLabel = styled.span`
  color: var(--primaryBlack50);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  white-space: nowrap;
`;

const TransactionRowTitle = styled(Link)`
  color: var(--primaryBlue);
  cursor: pointer;
  font-weight: bold;
  padding-left: 18px;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const MRRValue = styled.span`
  color: var(--primaryBlack);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 7px;
`;

export const isTransactionUpForRenewal = ({ transaction, selectedMonthDayJS }) => {
  const transactionEndDateDayJS = dayjs(transaction.end_date);

  const endOfPeriod = selectedMonthDayJS.endOf('month');

  // transaction is ending within the selected period
  if (transactionEndDateDayJS.isSameOrBefore(endOfPeriod, 'day')) {
    return true;
  }

  return false;
};

export const generateColumns = ({ selectedMonthDayJS, isARR, currency, dateFormat }) => {
  const RRText = isARR ? 'ARR' : 'MRR';
  return [
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      id: 'customerName',
    }, // This column is not displayed and is needed for sorting
    {
      Header: 'Deal Name',
      accessor: 'name',
      width: 350,
      Cell: ({ cell: { value }, row }) => {
        return row.canExpand ? (
          <StyledTableName>
            <Row horizontal="flex-start">
              <StyledTableNameTitle>
                {getCustomerDisplayName({
                  customerName: row.original.customerName,
                  customerId: row.original?.id,
                  customerEmail: row.original?.email,
                })}
              </StyledTableNameTitle>
              <GreyDot style={{ marginRight: 12, marginLeft: 12 }} />
              <MRRLabel>
                Previous {RRText}:
                <MRRValue>
                  {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: row.original?.previousMRR, currency })}
                </MRRValue>
              </MRRLabel>
              <GreyDot style={{ marginRight: 12, marginLeft: 12 }} />
              <MRRLabel style={{ marginRight: 24 }}>
                New {RRText}:
                <MRRValue>
                  {numberFormatter({ type: NUMBER_FORMATS.CURRENCY, rawValue: row.original?.currentMRR, currency })}
                </MRRValue>
              </MRRLabel>
              <TableSubRowsCount {...row.getToggleRowExpandedProps()}>
                {row.subRows.length}
                <TableSubRowsCountArrow isHidden={!row.isExpanded}>
                  <ArrowUp />
                </TableSubRowsCountArrow>
              </TableSubRowsCount>
            </Row>
          </StyledTableName>
        ) : value ? (
          <TransactionRowTitle to={`/transactions/${row.original.id}`} target="_blank">
            {value}
          </TransactionRowTitle>
        ) : (
          '[No Transaction Name]'
        );
      },
    },
    {
      Header: 'Booking Date',
      accessor: 'date',
      width: 120,
      Cell: ({ row }) =>
        !row.canExpand ? (
          getTransactionFieldValue({
            key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.DATE],
            transaction: row.original,
          })
        ) : (
          <></>
        ),
    },
    {
      Header: 'Total',
      accessor: 'amount',
      width: 120,
      Cell: ({ row }) =>
        !row.canExpand ? (
          <div style={{ fontWeight: 700 }}>
            {getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.AMOUNT],
              transaction: row.original,
              currency: row.original.currency ?? currency,
            })}
          </div>
        ) : (
          <></>
        ),
    },
    {
      Header: RRText,
      accessor: 'recurring_amount',
      width: 120,
      Cell: ({ cell: { value }, row }) =>
        !row.canExpand ? (
          <div style={{ fontWeight: 700 }}>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: isARR ? value * 12 : value,
              currency: row.original.currency ?? currency,
            })}
          </div>
        ) : (
          <></>
        ),
    },
    {
      Header: 'Type',
      accessor: 'recognition',
      width: 50,
      Cell: ({ row }) =>
        row.canExpand ? (
          ''
        ) : (
          <TransactionRecognitionIcon
            type={getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.RECOGNITION_TYPE],
              transaction: row.original,
            })}
          />
        ),
    },
    {
      Header: 'Dates',
      accessor: 'start_date',
      width: 220,
      Cell: ({ row }) => {
        const startDate = dayjs.utc(row.original?.start_date);
        const endDate = dayjs.utc(row.original?.end_date);

        const contractUpForRenewal = isTransactionUpForRenewal({
          transaction: row.original,
          selectedMonthDayJS,
        });

        return !row.canExpand ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {startDate.format(dateFormat)} - {contractUpForRenewal && <DangerIcon style={{ margin: '0 4px' }} />}
            <span style={contractUpForRenewal ? { color: 'var(--primaryRed)', fontWeight: 'bold' } : {}}>
              {endDate.isValid() ? endDate.format(dateFormat) : ' No end date'}
            </span>
          </div>
        ) : (
          ''
        );
      },
    },
    {
      Header: 'Product',
      accessor: 'product_name',
      width: 250,
      Cell: ({ row }) =>
        !row.canExpand ? (
          <TruncateStringWithTooltip length={10}>
            {getTransactionFieldValue({
              key: TRANSACTION_FIELD_VALUE_BY_ID[TRANSACTION_TABLE_COLUMN_KEY.PRODUCT],
              transaction: row.original,
            })}
          </TruncateStringWithTooltip>
        ) : (
          ''
        ),
    },
  ];
};

export const convertToARR = (data) => {
  const { upForRenewal, grossRenewed, netRenewed } = data;

  const newData = {
    ...data,
    upForRenewal: upForRenewal * 12,
    grossRenewed: grossRenewed * 12,
    netRenewed: netRenewed * 12,
  };

  for (const customer of Object.values(newData.customers)) {
    customer.currentMRR *= 12;
    customer.previousMRR *= 12;
  }

  return newData;
};

export const createCSV = ({ data, selectedMonthDayJS }) => {
  const csvData = [];
  for (const customer of Object.values(data)) {
    for (const transaction of customer.subRows) {
      delete transaction.customer_id;
      transaction.type = isTransactionUpForRenewal({ transaction, selectedMonthDayJS }) ? 'UP FOR RENEWAL' : 'RENEWED';
      csvData.push(transaction);
    }
  }

  const csvExporterOptions = mkConfig({
    filename: `Subscript Up For Renewal ${selectedMonthDayJS.format('MMM D YYYY')}`,
    useBom: true,
    useKeysAsHeaders: true,
  });
  const csv = generateCsv(csvExporterOptions)(csvData);
  download(csvExporterOptions)(csv);
};
