import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { TimeLoader, TimeLoaderContainer } from 'components/Loaders';
import { FlexerColumn } from 'components/Core';
import { AccountingRevenueChart } from './AccountingRevenueChart';
import { AccountingRevenueTable } from './AccountingRevenueTable';
import { ACCOUNTING_CURRENCIES_NO_GL } from 'views/Billing/consts';

export const AccountingRevenueChartAndTable = ({
  data,
  isLoading,
  handleModalOpen,
  setShowCustomersWithDeferred,
  setShowCustomersWithAccrued,
  setShowCustomersWithInvoiced,
  setShowCustomersWithAccountingRevenue,
  setModalGLIntegrationId,
}) => {
  const { glIntegrations, glIntegrationsById } = useContext(AppContext);

  const hasMultipleGLs = glIntegrations.length > 1;

  if (hasMultipleGLs || Object.keys(glIntegrationsById).length === 0) {
    glIntegrationsById[ACCOUNTING_CURRENCIES_NO_GL] = { service: ACCOUNTING_CURRENCIES_NO_GL };
  }

  return (
    <TimeLoaderContainer>
      {isLoading ? (
        <TimeLoader pageName="spreads" />
      ) : (
        <FlexerColumn>
          {!hasMultipleGLs && <AccountingRevenueChart data={data} />}
          <AccountingRevenueTable
            data={data}
            handleModalOpen={handleModalOpen}
            setShowCustomersWithDeferred={setShowCustomersWithDeferred}
            setShowCustomersWithAccrued={setShowCustomersWithAccrued}
            setShowCustomersWithInvoiced={setShowCustomersWithInvoiced}
            setShowCustomersWithAccountingRevenue={setShowCustomersWithAccountingRevenue}
            setModalGLIntegrationId={setModalGLIntegrationId}
            glIntegrationsById={glIntegrationsById}
          />
        </FlexerColumn>
      )}
    </TimeLoaderContainer>
  );
};
