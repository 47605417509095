import React, { useCallback } from 'react';
import { Flexer, FlexerColumn } from 'components/Core';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { FormikCustomInput } from 'components/Controls';
import { ReactComponent as CornerDownRightIcon } from 'images/corner-down-right-icon.svg';
import { BundleItemName, BundleItemTitle, BundleItemsWrapper } from '../../../InvoiceScheduleWarnings/styles';
import { getFieldChangesProps } from '../utils';

export const InvoiceItemBundleItems = ({
  items,
  invoiceItemIndex,
  disableEditing,
  values,
  currency,
  setFieldValue,
  syncInvoiceItemAmount,
  invoicingServiceDisplayName,
}) => {
  const handleInputChange = useCallback(
    (index, key, val) => {
      const updatedItems = items.map((item, i) => (i === index ? { ...item, [key]: val } : item));
      setFieldValue(`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].${key}`, val);
      syncInvoiceItemAmount(updatedItems);
    },
    [items, invoiceItemIndex, setFieldValue, syncInvoiceItemAmount],
  );

  return (
    <FlexerColumn padding="0 50px 0 36px">
      <BundleItemsWrapper
        data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-items`}
        gap="4px"
        width="100%"
        alignItems="center"
      >
        {items.map((item, index) => (
          <FlexerColumn gap="8px" width="100%" key={item.id}>
            <Flexer gap="12px" alignItems="center">
              <BundleItemTitle gap="16px" alignItems="center" justifyContent="flex-end" width="80%">
                <CornerDownRightIcon />
                {invoicingServiceDisplayName} product in bundle:
              </BundleItemTitle>
              <BundleItemName>
                <FormikCustomInput
                  name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].name`}
                  data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-product`}
                  type="text"
                  width="100%"
                  tooltipInputDisplay={`This product is part of a bundle and cannot be modified here. Please adjust in ${invoicingServiceDisplayName} if desired.`}
                  isDisabled
                />
              </BundleItemName>
              <FormikCustomInput
                name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].seats`}
                data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-seats`}
                type="number"
                precision={2}
                handleChange={(val) => handleInputChange(index, 'seats', val)}
                isDisabled={disableEditing}
                labelFlex="end"
                {...getFieldChangesProps({
                  values,
                  field: `invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].seats`,
                })}
              />
              <FormikCustomInput
                inputWidth="120px"
                name={`invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].unit_price`}
                data-cy={`schedule-modal__invoice__item${invoiceItemIndex}__bundle-item${index}-unit_price`}
                suffix={ISO_CODE_TO_SYMBOL[values?.invoice_items?.[0]?.currency ?? currency] ?? '$'}
                precision={2}
                type="number"
                handleChange={(val) => handleInputChange(index, 'unit_price', val)}
                isDisabled={disableEditing}
                labelFlex="end"
                {...getFieldChangesProps({
                  values,
                  field: `invoice_items[${invoiceItemIndex}].metadata.bundleItems[${index}].unit_price`,
                })}
              />
            </Flexer>
          </FlexerColumn>
        ))}
      </BundleItemsWrapper>
    </FlexerColumn>
  );
};
