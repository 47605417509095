import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { useExchangeRatesAPI } from 'api/exchangeRates';
import { CsvUpload } from 'shared/CsvUpload';
import { CONFIGURATION_TABS } from 'views/Configuration/consts';
import { getColumns } from './columns';
import { getSchema } from './schema';

dayjs.extend(utc);

export const RatesCsvUpload = () => {
  const [submittingForm, setSubmittingForm] = useState(false);

  const [fromCurrencies, setFromCurrencies] = useState({ currency_1: 'EUR' });

  const history = useHistory();

  const {
    appSettings: { currencyISOCode: homeCurrency },
    orgId,
    dateFormat,
  } = useContext(AppContext);

  const {
    operations: { bulkCreateExchangeRates },
  } = useExchangeRatesAPI({ orgId, autoFetch: false });

  const getColumnsWithCsvUploadState = useCallback(
    ({ formRef, csvColumnsMapper, setCsvColumnsMapper, csvColumns }) =>
      getColumns({
        formRef,
        csvColumns,
        fromCurrencies,
        setFromCurrencies,

        csvColumnsMapper,
        setCsvColumnsMapper,

        dateFormat,
      }),
    [dateFormat, fromCurrencies],
  );

  const handleSubmit = async (values) => {
    setSubmittingForm(true);

    const ratesForCreate = values?.reduce((acc, curr) => {
      Object?.keys(curr ?? {})?.forEach((key) => {
        if (!!curr?.[key]) {
          if (key.toLowerCase() !== 'date' && key !== 'rowIndex') {
            acc.push({
              date: dayjs(curr?.date).utc(true).format('YYYY-MM-DD'),
              rate: Number(curr?.[key]),
              from_currency: key,
              to_currency: homeCurrency,
              organization_id: orgId,
            });
          }
        }
      });

      return acc;
    }, []);

    await bulkCreateExchangeRates({ data: ratesForCreate });

    setTimeout(() => {
      history.push(`/configure?tab=${CONFIGURATION_TABS.EXCHANGE_RATES}`);
    }, 1000);

    setSubmittingForm(false);
  };

  const mapper = useMemo(() => ({ date: 'Date' }), []);

  return (
    <CsvUpload
      additionalInfoText={`Update existing currency rates or add new ones by using uploaded CSV rates FROM the selected currencies TO your home currency (${homeCurrency})`}
      backLink="/configure?tab=exchange-rates"
      onlyCreateMode={true}
      entityName="rates"
      isLoading={submittingForm}
      defaultMapperWithCreateOrUpdateMode={() => mapper}
      createSchema={getSchema()}
      handleSubmit={handleSubmit}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
    />
  );
};
