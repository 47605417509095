import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn, Spacer } from 'components/Core';
import {
  Popover,
  PopoverActions,
  PopoverButton,
  PopoverItemsContainer,
  PopoverListItem,
  PopoverPrompt,
  PopoverWrapper,
} from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { INVOICE_ITEM_TYPES } from 'views/Billing/consts';
import { ArrowNarrowLeftIcon } from 'components/Icons';
import { calculatePercentFromAmount } from 'views/Billing/InvoiceModal/utils';
import { ReactComponent as ArrowDownIcon } from 'images/chevron-down.svg';
import { ReactComponent as ArrowsRightLeftIcon } from 'images/arrows-right-left.svg';
import { ReactComponent as XIcon } from 'images/X-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check-with-no-circle.svg';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import {
  MatchInvoicesTable,
  MatchInvoicesTableColumnsWrapper,
  MatchInvoicesTableColumn,
  MatchInvoicesTableInvoicesRowTitle,
  StyledExternalLink,
  MatchInvoicesTableInvoicRowsWrapper,
  MatchInvoicesTableInvoicesRowItem,
} from './styles';

const Tag = styled(Centerer)`
  border-radius: 4px;
  padding: ${({ padding }) => padding ?? '4px'};
  border: 1px solid var(--neutralGray);
  font-size: 9px;
  font-weight: 900;
  color: var(--primaryBlack50);
  width: fit-content;
`;

const PlaceholderText = styled.div`
  color: var(--primaryBlack30);
`;

const CustomSelectorWrapper = styled(FlexBetweenContainer)`
  width: 100%;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack50);
  }
`;

const CurrencySymbol = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlack50);
`;

const StyledArrowsRightLeftIcon = styled(ArrowsRightLeftIcon)`
  width: 16px;
  height: 16px;
  opacity: 0.5;
`;

const FooterText = styled.i`
  font-size: 12px;
  color: var(--primaryBlack50);
`;

const StyledXIcon = styled(XIcon)`
  width: 10px;
  height: 10px;

  path {
    fill: white;
    opacity: 0.5;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 11px;
  height: 11px;

  path {
    fill: white;
  }
`;

const StyledCustomSelector = ({ options, handleChange, value, placeholder, disabled, dataCy, ...props }) => {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useClickOutside(() => setShowOptions(false));

  const selectedOption = options.find((option) => option.value === value);

  return (
    <PopoverWrapper width="100%" ref={dropdownRef}>
      <CustomSelectorWrapper data-cy={dataCy} onClick={() => setShowOptions((prev) => !prev)} disabled={disabled}>
        <Flexer width="100%">
          {selectedOption ? selectedOption.label : <PlaceholderText>{placeholder}</PlaceholderText>}
        </Flexer>
        <Spacer width="12px" />
        <StyledArrowDownIcon />
      </CustomSelectorWrapper>

      {showOptions && (
        <Popover YOffset={30} XOffset={90} width="100%" {...props}>
          <PopoverItemsContainer maxHeight="300px">
            {options.map((option) => (
              <PopoverListItem
                key={option.value}
                onClick={() => {
                  handleChange(option);
                  setShowOptions(false);
                }}
                isSelected={option.value === value}
                hoverColor="var(--primaryBlue5)"
                backgroundColor={option.value === value ? 'var(--primaryBlue10)' : null}
              >
                {option.label}
              </PopoverListItem>
            ))}
          </PopoverItemsContainer>
        </Popover>
      )}
    </PopoverWrapper>
  );
};

export const MatchImportedInvoicesModal = ({ onClose, name, onSave }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const { invoicingService, transactionOptions, productOptions, invoicingScheduleFormValues } = useContext(
    InvoicingScheduleContext,
  );

  const { values, setFieldValue } = useFormikContext();

  const [openConfirmPopover, setOpenConfirmPopover] = useState(false);

  const importedInvoices = values[name];

  const hasItemsWithoutTransaction = importedInvoices.some((invoice) =>
    invoice.invoice_items?.some(
      (item) => !Object.values(INVOICE_ITEM_TYPES).includes(item.type) && !item.transaction_id,
    ),
  );

  const popoverRef = useClickOutside(() => setOpenConfirmPopover(false));

  const handleTransactionChange = useCallback(
    ({ value: transactionId, name, item }) => {
      const selectedTransaction = transactionOptions.find(({ value }) => value === transactionId);

      setFieldValue(name, {
        ...item,
        transaction_id: transactionId,
        product_id: selectedTransaction?.transaction?.product_id,
      });
    },
    [setFieldValue, transactionOptions],
  );

  const handleProductChange = useCallback(
    ({ value: productId, name, item }) => {
      setFieldValue(name, {
        ...item,
        product_id: productId,
      });
    },
    [setFieldValue],
  );

  const handleOnBack = () => {
    setFieldValue(
      name,
      importedInvoices.map((invoice) => ({
        ...invoice,
        invoice_items: invoice.invoice_items.map((item) => ({
          ...item,
          transction_id: null,
          product_id: null,
        })),
      })),
    );
    onClose();
  };

  const handleOnSave = () => {
    onSave(importedInvoices);
    onClose();
  };

  const getInvoiceBaseAmount = (invoice) => {
    return invoice.invoice_items.reduce(
      (acc, item) => (Object.values(INVOICE_ITEM_TYPES).includes(item.type) ? acc : acc + item.amount),
      0,
    );
  };

  return (
    <ModalContainer>
      <Modal padding="0px" width="1040px" height="675px" overflow="visible" compact>
        <ModalHeader background="var(--primaryBlack1)" padding="20px 0px">
          <ModalCloseIcon onClick={onClose} />
          <ModalTitle fontSize="18px">
            <b>Match the imported invoice items with transactions</b>
          </ModalTitle>
        </ModalHeader>

        <ModalBody background="var(--primaryBlack1)">
          <MatchInvoicesTable>
            <MatchInvoicesTableColumnsWrapper>
              <MatchInvoicesTableColumn>Imported inv. / item</MatchInvoicesTableColumn>
              <MatchInvoicesTableColumn>
                <Tag padding="2px">
                  <StyledArrowsRightLeftIcon />
                </Tag>
                <Spacer width="8px" /> <div>Transaction</div> <Spacer width="8px" /> <Tag>IN SUBSCRIPT</Tag>
              </MatchInvoicesTableColumn>
              <MatchInvoicesTableColumn>
                <FlexBetweenContainer width="100%">
                  <div>Product</div>
                  <div style={{ marginRight: '20px' }}>{invoicingService} product</div>
                </FlexBetweenContainer>
              </MatchInvoicesTableColumn>
              <MatchInvoicesTableColumn justifyContent="flex-end">qty</MatchInvoicesTableColumn>
              <MatchInvoicesTableColumn justifyContent="flex-end">Amount</MatchInvoicesTableColumn>
            </MatchInvoicesTableColumnsWrapper>

            <Spacer height="16px" />

            <FlexerColumn gap="12px">
              {importedInvoices?.map((invoice, invoiceIndex) => (
                <FlexerColumn gap="8px">
                  <MatchInvoicesTableInvoicesRowTitle>
                    <b>
                      #{invoice.invoice_number ?? invoice.external_id} ({dayjs.utc(invoice.date).format('MMM DD, YYYY')}
                      )
                    </b>
                    {!!invoice.invoice_external_url && (
                      <StyledExternalLink onClick={() => window.open(invoice.invoice_external_url, '_blank')} />
                    )}
                  </MatchInvoicesTableInvoicesRowTitle>

                  <FlexerColumn gap="0px" height="fit-content">
                    {invoice.invoice_items?.map((item, index) => {
                      const isTypedItem = Object.values(INVOICE_ITEM_TYPES).includes(item.type);

                      return (
                        <MatchInvoicesTableInvoicRowsWrapper key={`${invoice.external_id}-${index}`}>
                          <MatchInvoicesTableInvoicesRowItem
                            data-cy={`match-imported-invoices-modal__item-name--${index}`}
                          >
                            {isTypedItem ? item.type : item.name}
                          </MatchInvoicesTableInvoicesRowItem>

                          <MatchInvoicesTableInvoicesRowItem>
                            {!isTypedItem && (
                              <StyledCustomSelector
                                name={`${name}[${invoiceIndex}].invoice_items[${index}].transaction_id`}
                                options={transactionOptions}
                                handleChange={(result) =>
                                  handleTransactionChange({
                                    ...result,
                                    item,
                                    name: `${name}[${invoiceIndex}].invoice_items[${index}]`,
                                  })
                                }
                                value={item.transaction_id}
                                placeholder="Select transaction..."
                                disabled={isTypedItem}
                                dataCy={`match-imported-invoices-modal__transaction-selector--${index}`}
                              />
                            )}
                          </MatchInvoicesTableInvoicesRowItem>

                          <MatchInvoicesTableInvoicesRowItem>
                            {!isTypedItem && (
                              <StyledCustomSelector
                                name={`${name}[${invoiceIndex}].invoice_items[${index}].product_id`}
                                options={productOptions.map((option) => ({
                                  ...option,
                                  label: (
                                    <FlexBetweenContainer width="100%" gap="8px">
                                      <div>{option.label}</div>
                                      {option.subValue ? <div>{option.subLabel}</div> : <i>{option.subLabel}</i>}
                                    </FlexBetweenContainer>
                                  ),
                                }))}
                                handleChange={(result) =>
                                  handleProductChange({
                                    ...result,
                                    item,
                                    name: `${name}[${invoiceIndex}].invoice_items[${index}]`,
                                  })
                                }
                                value={item.product_id}
                                placeholder="Select product..."
                                XOffset={165}
                                disabled={isTypedItem}
                              />
                            )}
                          </MatchInvoicesTableInvoicesRowItem>

                          <MatchInvoicesTableInvoicesRowItem justifyContent="flex-end">
                            {isTypedItem
                              ? numberFormatter({
                                  rawValue:
                                    calculatePercentFromAmount({
                                      amount: item.amount,
                                      total: getInvoiceBaseAmount(invoice),
                                    }) / 100,
                                  type: NUMBER_FORMATS.PERCENT,
                                  decimalPlaces: 3,
                                })
                              : item.seats || 1}
                          </MatchInvoicesTableInvoicesRowItem>

                          <MatchInvoicesTableInvoicesRowItem>
                            <FlexBetweenContainer width="100%">
                              <CurrencySymbol>
                                {ISO_CODE_TO_SYMBOL[invoice.currency ?? defaultOrgCurrency]}
                              </CurrencySymbol>

                              <div>
                                {numberFormatter({
                                  type: NUMBER_FORMATS.NUMBER,
                                  rawValue: item.amount,
                                  decimalPlaces: 2,
                                })}
                              </div>
                            </FlexBetweenContainer>
                          </MatchInvoicesTableInvoicesRowItem>
                        </MatchInvoicesTableInvoicRowsWrapper>
                      );
                    })}
                  </FlexerColumn>
                </FlexerColumn>
              ))}
            </FlexerColumn>
          </MatchInvoicesTable>
        </ModalBody>

        <ModalFooter centered>
          <FlexBetweenContainer width="100%" alignItems="center">
            <Flexer gap="8px" alignItems="center">
              <StyledArrowsRightLeftIcon />
              <FooterText>Some invoice items were automatically matched with transactions by Subscript</FooterText>
            </Flexer>

            <Flexer gap="8px" alignItems="center">
              <ModalButton onClick={handleOnBack} fontSize="12px">
                <ArrowNarrowLeftIcon size="16px" />
                <Spacer width="6px" />
                <b>Back</b>
              </ModalButton>

              <PopoverWrapper ref={popoverRef}>
                <ModalButton
                  primary
                  fontSize="12px"
                  data-cy="match-imported-invoices-modal__save-imports-button"
                  onClick={() =>
                    hasItemsWithoutTransaction && invoicingScheduleFormValues?.id
                      ? setOpenConfirmPopover(true)
                      : handleOnSave()
                  }
                >
                  <b>Finish Importing</b>
                </ModalButton>

                {openConfirmPopover && (
                  <Popover darkMode width="240px">
                    <PopoverPrompt>
                      Not all items have matched transactions. Do you want to continue anyway?
                    </PopoverPrompt>

                    <PopoverActions>
                      <PopoverButton onClick={() => setOpenConfirmPopover(false)}>
                        <StyledXIcon />
                        No
                      </PopoverButton>
                      <PopoverButton
                        onClick={() => {
                          handleOnSave();
                          setOpenConfirmPopover(false);
                        }}
                      >
                        <StyledCheckIcon />
                        Yes
                      </PopoverButton>
                    </PopoverActions>
                  </Popover>
                )}
              </PopoverWrapper>
            </Flexer>
          </FlexBetweenContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useMatchImportedInvoicesModal = ({ name }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = (props) => {
    setShowModal(true);
    setModalProps(props ?? {});
  };
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <MatchImportedInvoicesModal onClose={closeModal} name={name} {...modalProps} /> : null),
    [showModal, closeModal, modalProps, name],
  );

  return { openModal, closeModal, Modal, isModalOpen: showModal };
};
