import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { AppContext } from 'AppContext';
import { useInvoiceAPI } from 'api/billing/hooks';
import { Centerer } from 'components/Core';
import { SizedLoader } from 'components/Loaders';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES } from '../consts';
import { useInvoicingScheduleModal } from '../InvoicingScheduleModal';

export const BillingSingleInvoice = () => {
  let history = useHistory();
  const { orgId } = useContext(AppContext);
  const { invoiceId } = useParams();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const invoiceRef = useRef(null);

  const navigateToInvoicesListPage = useRef(() => {
    history.push(BILLING_PAGES_ROUTES.INVOICES, { status: INVOICE_STATUSES.ALL });
  }).current;

  const { Modal: InvoicingScheduleModal, openModal: openInvoicingScheduleModal } = useInvoicingScheduleModal({
    onClose: navigateToInvoicesListPage,
  });

  const { data: invoice, isLoading, error } = useInvoiceAPI({
    orgId,
    invoiceId: invoiceId,
    autoFetch: !!invoiceId,
  });

  useEffect(() => {
    if (!isNil(error)) {
      alert('Could not fetch invoice. Redirecting to invoices list.');
      navigateToInvoicesListPage();
    } else if (!isLoading && invoice && invoice !== invoiceRef.current) {
      invoiceRef.current = invoice;
      if (invoice.invoicing_schedule_id) {
        openInvoicingScheduleModal({
          invoicingSchedule: { id: invoice.invoicing_schedule_id },
          invoice: { id: invoice.id },
        });
        setShouldShowModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error, invoice]);

  if (isLoading && !invoiceRef.current) {
    return (
      <Centerer width="100%" height="100%">
        <SizedLoader size={40} />
      </Centerer>
    );
  }

  return shouldShowModal ? <InvoicingScheduleModal /> : null;
};
