import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';
import { AppContext } from 'AppContext';
import { useContractAPI } from 'api/contracts/hooks';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  HeaderCellWrapper,
  SortIconWrapper,
} from 'components/Table';
import { getColumns } from './columns';

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 24px;
`;

const TableLabel = styled.div`
  margin: 20px 0px;
  font-size: 18px;
  font-weight: 900;
  color: var(--primaryBlack);

  span {
    color: var(--primaryBlack50);
  }
`;

export const ContractTransactionsTable = ({ contractData, transactionsData }) => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { removeTransactionFromContract },
  } = useContractAPI({ orgId, contractId: contractData.id, enabled: false });

  const onRemoveTransaction = useCallback(
    async ({ transactionId }) => {
      await removeTransactionFromContract({ transactionId });
    },
    [removeTransactionFromContract],
  );

  const data = useMemo(() => transactionsData, [transactionsData]);

  const columns = useMemo(() => getColumns({ onRemoveTransaction }), [onRemoveTransaction]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <Fragment>
      <TableLabel>
        Transactions <span>of Contract</span>
      </TableLabel>
      <TableContainer {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  key={column.id}
                  customWidth={column.width}
                  alignRight={column.alignRight}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <ReactTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ReactTableCell
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ))}
                  </ReactTableRow>
                </Fragment>
              );
            })}
          </ReactTableBody>
        ) : null}
      </TableContainer>
    </Fragment>
  );
};
