import React from 'react';
import { ContractsListHeader } from './ContractsListHeader';
import { ContractsListTable } from './ContractsListTable';

export const ContractsListContainer = () => {
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ContractsListHeader />
      <ContractsListTable />
    </div>
  );
};
