import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useExpanded, useGlobalFilter, useSortBy, useTable } from 'react-table';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { useInvoicePdfAPI, useInvoicesAPI } from 'api/billing';
import { useMVP } from 'utils/hooks';
import { downloadBlobFile } from 'utils/export';
import { ReactComponent as SortIcon } from 'images/sort-descending.svg';
import { ReactComponent as Link2Icon } from 'images/link2.svg';
import { Button } from 'components/Buttons';
import { SizedLoader } from 'components/Loaders';
import { Centerer, Row, Spacer } from 'components/Core';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt } from 'components/Portal';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  ReactTableCell,
  EmptyTableContent,
  EmptyTableContentAction,
  customGlobalFilter,
  HeaderCellWrapper,
  SortIconWrapper,
} from 'components/Table';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { MultiselectRibbon } from 'components/Blocks';
import { AllRowsSelectorDropdown } from 'components/Table/AllRowsSelectorDropdown';
import { BulkSendInvoicesModal } from '../BulkSendInvoicesModal';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES, SERVICE_WITHOUT_INVOICE_SEND_EMAIL } from '../consts';
import { getAutoChargeJobRunsAt, isInvoiceOverdue } from '../utils';
import { MarkAsPaidPopoverBody } from '../InvoicingScheduleModal/InvoicingScheduleTabsPanel/InvoiceActions/InvoiceActions';
import { BillingContext } from '../BillingContext';
import { HIDDEN_DEFAULT_COLUMNS } from './consts';
import { AVAILABLE_STATUSES_FOR_BULK_ACTIONS, generateColumns } from './columns';
import { BulkPdfDownloadModal } from './BulkPdfDownloadModal';
import { useBulkSaveToGlModal } from './useBulkSaveToGlModal';

const LinkIcon = styled(Link2Icon)`
  margin-left: 16px;
  cursor: pointer;
`;

const InvoiceTableRow = styled(ReactTableRow)`
  position: relative;

  ${LinkIcon} {
    display: none;
  }

  ${({ overdueInvoiceWithReminders }) =>
    overdueInvoiceWithReminders &&
    `
    padding-bottom: 52px;
    height: auto;
  `}

  &:hover {
    ${LinkIcon} {
      display: flex;
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
  height: 75vh;
  overflow: scroll;
`;

const BulkCreateButton = styled(Button)`
  width: fit-content;
  color: white;
  background: var(--primaryGreen);
`;

const BulkPaidButton = styled(Button)`
  color: white;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const SelectAllCheckbox = ({
  dataForTable,
  allSelectableInvoicesCount,
  allInvoicesSelected,
  setAllInvoicesSelected,
  invoicesSelected,
  setInvoicesSelected,
}) => {
  const isMVP = useMVP();
  const allPageInvoices = useMemo(() => dataForTable?.flatMap(({ subRows }) => subRows), [dataForTable]);

  const allPageInvoicesSelected = useMemo(
    () =>
      invoicesSelected.length > 0 &&
      allPageInvoices.every(
        ({ id, invoice_status }) =>
          invoicesSelected.find((invoice) => invoice.id === id) ||
          !AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(invoice_status),
      ),
    [allPageInvoices, invoicesSelected],
  );

  const allPageSelectableInvoices = useMemo(
    () => allPageInvoices.filter(({ invoice_status }) => AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(invoice_status)),
    [allPageInvoices],
  );

  useEffect(() => {
    if (allPageSelectableInvoices.length > 0 && allInvoicesSelected) {
      setInvoicesSelected(allPageSelectableInvoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPageSelectableInvoices]);

  const handleAllPageInvoicesSelectedChange = ({ target: { checked } }) => {
    if (!checked) {
      setInvoicesSelected([]);
    } else {
      setInvoicesSelected(allPageSelectableInvoices);
    }
  };

  const handleAllInvoicesSelectedChange = (props) => {
    handleAllPageInvoicesSelectedChange(props);
    setAllInvoicesSelected(props?.target?.checked);
  };

  return (
    <AllRowsSelectorDropdown
      // [JB 2024-11-15] MVP: disable select all for now, since it involves doing a few changes on bulk calls on the backend
      disableSelectAll={!isMVP}
      getToggleAllPageRowsSelectedProps={() => ({
        checked: allPageInvoicesSelected,
        indeterminate: invoicesSelected.length > 0 && !allPageInvoicesSelected,
        onChange: handleAllPageInvoicesSelectedChange,
      })}
      getToggleAllRowsSelectedProps={() => ({
        checked: allInvoicesSelected,
        indeterminate: invoicesSelected.length > 0 && !allInvoicesSelected,
        onChange: handleAllInvoicesSelectedChange,
      })}
      page={allPageSelectableInvoices}
      rows={Array.from({ length: allSelectableInvoicesCount }).map((_, index) => ({ id: index }))}
    />
  );
};

export const InvoicesTable = ({
  currency,
  dataForTable,
  invoicesCountData = [],
  setTableRows = () => {},
  onInvoiceClick,
  setAllColumnsState,
  isFetchingInvoices,
  initialSortBy = [],
  setSortBy,
  hiddenInvoicesColumns,
  setHiddenInvoicesColumns,
}) => {
  const { integrations, dateFormat, orgId } = useContext(AppContext);
  const {
    billingDataFilter,
    invoiceStatuses,
    invoicesSearchQuery,
    metadataFilter,
    bulkMarkInvoicesAsSent,
    bulkMarkInvoicesAsPaid,
    openInvoicingScheduleModal,
    invoicesGroupBy,
  } = useContext(BillingContext);

  const {
    operations: { bulkDownloadInvoicesPdf },
  } = useInvoicePdfAPI({ orgId, autoFetch: false });

  const {
    operations: { bulkSaveInvoicesToGl },
  } = useInvoicesAPI({ orgId, autoFetch: false });

  const history = useHistory();
  const { state } = useLocation();

  const isAllInvoicesPage = state?.status === INVOICE_STATUSES.ALL;

  const invoicingService = integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service;
  const canSendEmail = !SERVICE_WITHOUT_INVOICE_SEND_EMAIL?.includes(invoicingService);

  const [allInvoicesSelected, setAllInvoicesSelected] = useState(false);
  const allSelectableInvoicesCount = useMemo(
    () =>
      invoicesCountData.reduce((acc, curr) => {
        if (AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(curr?.invoice_status)) {
          return acc + parseInt(curr?.count, 10) ?? 0;
        }
        return acc;
      }, 0),
    [invoicesCountData],
  );

  const [invoicesSelected, setInvoicesSelected] = useState([]);
  const [invoicesForDownloadCount, setInvoicesForDownloadCount] = useState();
  const [fileResponse, setFileResponse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bulkPaidAtDate, setBulkPaidAtDate] = useState(new Date());
  const [showBulkMarkAsPaidPopover, setShowBulkMarkAsPaidPopover] = useState(false);
  const [showBulkMarkAsSentPopover, setShowBulkMarkAsSentPopover] = useState(false);
  const columns = useMemo(() => {
    const autoChargeJobRunsAt = getAutoChargeJobRunsAt();
    return generateColumns({
      invoicesSelected,
      setInvoicesSelected,
      currency,
      onInvoiceClick,
      autoChargeJobRunsAt,
      invoicesGroupBy,
      dateFormat,
    });
  }, [invoicesSelected, currency, onInvoiceClick, invoicesGroupBy, dateFormat]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
    toggleAllRowsExpanded,
    state: { hiddenColumns, sortBy },
  } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      expandSubRows: true,
      manualSortBy: true,
      initialState: {
        sortBy: initialSortBy ?? [],
        hiddenColumns: hiddenInvoicesColumns ?? HIDDEN_DEFAULT_COLUMNS,
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    setTimeout(toggleAllRowsExpanded(true));
  }, [toggleAllRowsExpanded, dataForTable]);

  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useEffect(() => {
    if (setHiddenInvoicesColumns) setHiddenInvoicesColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenInvoicesColumns]);

  useEffect(() => {
    if (setAllColumnsState) setAllColumnsState(allColumns);
  }, [allColumns, setAllColumnsState]);

  useEffect(() => {
    setInvoicesSelected([]);
  }, [billingDataFilter, invoiceStatuses, invoicesSearchQuery, metadataFilter]);

  useEffect(() => setTableRows(rows?.filter((row) => !row.canExpand)), [rows, setTableRows]);

  const handleBulkSendInvoices = () => setShowModal(true);

  const { Modal: BulkSaveToGlModal, openModal: openBulkSaveToGlModal } = useBulkSaveToGlModal();

  const someInvoicesWithoutEmails = invoicesSelected.some((invoice) => !invoice?.customerInvoicingDetails?.contacts);
  const invoiceIds = invoicesSelected.map(({ id }) => id);

  const getEmptyTableText = () =>
    isAllInvoicesPage ? (
      <EmptyTableContent>
        You don't have any invoices, but you can
        <EmptyTableContentAction onClick={openInvoicingScheduleModal}>create them manually</EmptyTableContentAction>
      </EmptyTableContent>
    ) : (
      <span>
        You don't have any invoices with these types Check your
        <EmptyTableContentAction
          onClick={() =>
            history.push({
              pathname: BILLING_PAGES_ROUTES.INVOICES,
              state: { status: INVOICE_STATUSES.ALL },
            })
          }
        >
          all invoices
        </EmptyTableContentAction>
      </span>
    );

  const handleBulkPdfDownload = async () => {
    try {
      setFileResponse(null);
      setInvoicesForDownloadCount(invoicesSelected.length);
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
      const response = await bulkDownloadInvoicesPdf({ invoiceIds: invoicesSelected.map(({ id }) => id) });

      if (response) {
        setFileResponse(response);
        downloadBlobFile({ file: response?.data, filename: response?.name, rawName: true });
      }
    } catch (err) {
      setFileResponse(null);
      setInvoicesForDownloadCount();
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
    }
  };

  const handleBulkSaveToGl = async () => {
    try {
      await bulkSaveInvoicesToGl.mutateAsync({ invoiceIds });

      setInvoicesSelected([]);
      setAllInvoicesSelected(false);

      openBulkSaveToGlModal({ invoiceIds });
    } catch (err) {
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
    }
  };

  const handleBulkMarkAsSent = async () => {
    try {
      await bulkMarkInvoicesAsSent({
        data: { invoiceIds },
      });
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
      setShowBulkMarkAsSentPopover(false);
    } catch (err) {
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
    }
  };

  return (
    <>
      <TableWrapper {...getTableProps()} data-cy="invoices-table">
        <ReactTableHeader backgroundColor="var(--secondaryGray)">
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
              <Centerer>
                {allSelectableInvoicesCount > 0 && (
                  <SelectAllCheckbox
                    dataForTable={dataForTable}
                    allSelectableInvoicesCount={allSelectableInvoicesCount}
                    allInvoicesSelected={allInvoicesSelected}
                    setAllInvoicesSelected={setAllInvoicesSelected}
                    invoicesSelected={invoicesSelected}
                    setInvoicesSelected={setInvoicesSelected}
                  />
                )}

                <Spacer width="16px" />

                {headerGroup.headers.map((column) => (
                  <ReactTableHeaderColumn
                    {...column.getHeaderProps(
                      column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                    )}
                    customWidth={column.width}
                    alignRight={column.alignRight}
                    data-cy={`invoices-table__${column?.id}-header`}
                  >
                    <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                      {column.render('Header')}

                      {column.isSorted && (
                        <SortIconWrapper
                          data-cy={`invoices-table__${column?.id}-sort-icon--${column?.isSortedDesc ? 'desc' : 'asc'}`}
                          isSortedDesc={column.isSortedDesc}
                        >
                          <SortIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </ReactTableHeaderColumn>
                ))}
              </Centerer>
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {isFetchingInvoices ? (
          <Centerer height="100px">
            <SizedLoader size={40} />
          </Centerer>
        ) : rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <InvoiceTableRow
                  {...row.getRowProps()}
                  subRowBackgroundColor={
                    !row.canExpand &&
                    ([INVOICE_STATUSES.SAVE_FAILED, INVOICE_STATUSES.SENT_FAILED].includes(
                      row.original?.invoice_status,
                    ) ||
                      isInvoiceOverdue({ invoice: row.original })) &&
                    'var(--newRed5)'
                  }
                  rowBackgroundColor={row.original?.isOverdueGroup && 'var(--newRed5)'}
                  overdueInvoiceWithReminders={
                    row?.original?.has_unsent_reminders && row?.original?.sent_at && !row?.original?.auto_charge
                  }
                  subRow={!row.canExpand}
                  height={row.canExpand ? '68px' : undefined}
                  noRowHover
                  noSpaceBetween
                  data-cy={!row.canExpand ? 'invoices-table--invoice-row' : 'invoices-table--date-row'}
                >
                  {row.cells.map((cell) =>
                    row.canExpand ? (
                      <div {...cell.getCellProps()}>{cell.render('Cell')}</div>
                    ) : (
                      <ReactTableCell
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ),
                  )}
                </InvoiceTableRow>
              );
            })}
          </ReactTableBody>
        ) : (
          <EmptyTableContent>{getEmptyTableText()}</EmptyTableContent>
        )}
        {invoicesSelected.length > 0 && (
          <MultiselectRibbon
            label={`${allInvoicesSelected ? allSelectableInvoicesCount : invoicesSelected.length} selected items:`}
            insertActionsDirectly={true}
            actions={
              <Row gap="8px" style={{ marginLeft: 8 }}>
                {canSendEmail && (
                  <TooltipContainer
                    width={300}
                    hideArrow
                    yOffset={40}
                    direction={DIRECTIONS.TOP}
                    isVisible={someInvoicesWithoutEmails}
                    toolTipContent="One or more customers do not have emails. Please send invoice manually"
                  >
                    <BulkCreateButton
                      fontWeight="700"
                      disabled={someInvoicesWithoutEmails}
                      onClick={handleBulkSendInvoices}
                      data-cy="invoices-table__bulk-send-invoices-button"
                    >
                      <span>Bulk Send Invoices</span>
                    </BulkCreateButton>
                  </TooltipContainer>
                )}
                <BulkPaidButton
                  fontWeight="700"
                  onClick={() => setShowBulkMarkAsSentPopover(!showBulkMarkAsSentPopover)}
                  data-cy="invoices-table__bulk-mark-as-sent-button"
                >
                  <span>Mark as Sent</span>
                </BulkPaidButton>
                <BulkPaidButton
                  fontWeight="700"
                  onClick={() => setShowBulkMarkAsPaidPopover(!showBulkMarkAsPaidPopover)}
                  data-cy="invoices-table__bulk-mark-as-paid-button"
                >
                  <span>Mark as Paid</span>
                </BulkPaidButton>
                <BulkPaidButton
                  disabled={invoicesSelected?.some(({ invoice_status }) =>
                    [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoice_status),
                  )}
                  fontWeight="700"
                  onClick={handleBulkSaveToGl}
                  data-cy="invoices-table__bulk-save-to-gl-button"
                >
                  <span>Save to GL</span>
                </BulkPaidButton>
                <BulkPaidButton
                  disabled={invoicesSelected?.some(({ invoice_status }) =>
                    [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoice_status),
                  )}
                  fontWeight="700"
                  onClick={handleBulkPdfDownload}
                  data-cy="invoices-table__bulk-pdf-download-button"
                >
                  <span>Download PDFs</span>
                </BulkPaidButton>
                {!!showBulkMarkAsPaidPopover && (
                  <Popover XOffset={350} YOffset={-150} zIndex={51} darkMode width="220px">
                    {MarkAsPaidPopoverBody({
                      paidAt: bulkPaidAtDate,
                      bulk: true,
                      setPaidAt: setBulkPaidAtDate,
                      onClose: () => setShowBulkMarkAsPaidPopover(false),
                      onSubmit: async () => {
                        await bulkMarkInvoicesAsPaid({
                          data: { invoiceIds, paidAt: dayjs.utc(bulkPaidAtDate).format('YYYY-MM-DD') },
                        });
                        setInvoicesSelected([]);
                        setShowBulkMarkAsPaidPopover(false);
                      },
                    })}
                  </Popover>
                )}
                {!!showBulkMarkAsSentPopover && (
                  <Popover XOffset={350} YOffset={-90} zIndex={51} darkMode width="220px">
                    <PopoverPrompt>Are you sure you want to mark these invoices as sent?</PopoverPrompt>
                    <PopoverActions>
                      <PopoverButton onClick={() => setShowBulkMarkAsSentPopover(false)}>No</PopoverButton>
                      <PopoverButton
                        data-cy="invoices-table__confirm-mark-as-sent-option"
                        onClick={handleBulkMarkAsSent}
                        primary
                      >
                        Yes
                      </PopoverButton>
                    </PopoverActions>
                  </Popover>
                )}
              </Row>
            }
            onResetSelection={() => setInvoicesSelected([])}
          />
        )}
      </TableWrapper>

      <BulkPdfDownloadModal
        fileResponse={fileResponse}
        setFileResponse={setFileResponse}
        invoicesForDownloadCount={invoicesForDownloadCount}
        setInvoicesForDownloadCount={setInvoicesForDownloadCount}
      />

      {showModal && (
        <BulkSendInvoicesModal
          invoiceIds={invoiceIds}
          setShowModal={setShowModal}
          setInvoicesSelected={setInvoicesSelected}
        />
      )}

      <BulkSaveToGlModal />
    </>
  );
};
