import React, { Fragment, useContext } from 'react';

import { AppContext } from 'AppContext';
import { useUrlQuery } from 'utils/hooks';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';

import { CONFIGURATION_TABS } from './consts';
import { ConfigurationHeader } from './ConfigurationHeader';
import { MembersTab } from './Tabs/MembersTab';
import { ProductsTab } from './Tabs/ProductsTab';
import { GeneralLedgerTab } from './Tabs/GeneralLedgerTab';
import { ExternalSourcesTab } from './Tabs/ExternalSourcesTab';
import { GeneralSettingsTab } from './Tabs/GeneralSettingsTab/GeneralSettingsTab';
import { SubscriptBillingTab } from './Tabs/SubscriptBillingTab';
import { ExchangeRatesTab } from './Tabs/ExchangeRatesTab';
import { CSVImportsTab } from './Tabs/CSVImportsTab';

const Configuration = () => {
  const { integrations } = useContext(AppContext);
  const query = useUrlQuery();
  const tab = query.get('tab') ?? CONFIGURATION_TABS.PRODUCTS;
  const integrationQueryParam = query.get('integration');
  const integrationId = integrationQueryParam ? parseInt(integrationQueryParam) : null;
  const selectedIntegration =
    tab === CONFIGURATION_TABS.SOURCES
      ? integrationId
        ? // if integrationId is provided, use it
          integrations?.find((integration) => integration.id === integrationId)
        : // otherwise use first integration added as default
          integrations.findLast((integration) =>
            [INTEGRATION_TYPES.CRM, INTEGRATION_TYPES.GL].includes(integration.type),
          )
      : null;

  const getActiveTab = () => {
    switch (tab) {
      case CONFIGURATION_TABS.MEMBERS:
        return <MembersTab />;
      case CONFIGURATION_TABS.EXCHANGE_RATES:
        return <ExchangeRatesTab />;
      case CONFIGURATION_TABS.PRODUCTS:
        return <ProductsTab />;
      case CONFIGURATION_TABS.CSV_IMPORTS:
        return <CSVImportsTab />;
      case CONFIGURATION_TABS.GENERAL_SETTINGS:
        return <GeneralSettingsTab />;
      case CONFIGURATION_TABS.SUBSCRIPT_BILLING:
        return <SubscriptBillingTab />;
      case CONFIGURATION_TABS.SOURCES:
        switch (selectedIntegration.type) {
          case INTEGRATION_TYPES.CRM:
            return (
              <ExternalSourcesTab
                configSelectedIntegration={selectedIntegration}
                selectedIntegration={selectedIntegration}
              />
            );
          case INTEGRATION_TYPES.GL:
            return (
              <GeneralLedgerTab
                selectedService={getServiceCategory(selectedIntegration?.service)}
                selectedIntegration={selectedIntegration}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {/* TODO: remove this when all non-source tabs import ConfigurationHeader themselves */}
      {tab !== CONFIGURATION_TABS.SOURCES && (
        <ConfigurationHeader activeTab={tab} selectedIntegration={selectedIntegration} />
      )}
      {getActiveTab()}
    </Fragment>
  );
};

export { Configuration };
