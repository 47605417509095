import { useCallback, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useHistory } from 'react-router';
import { AppContext } from 'AppContext';
import { getTransaction, getTransactionSpreads, getTransactions } from 'api/transactions';
import { compareDate } from 'utils/dateUtils';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { prepareData } from 'models/externalUpdates';
import { normalizeAmountFields } from 'models/transaction';
import { calculateRecognizePercent } from 'shared/TransactionContent/TransactionBody/TransactionSpread/utils';
import { isTransactionLocked } from 'views/Transactions/utils';
import { TransactionContext } from './TransactionContext';
import { TRANSACTION_MODAL_MODE, INITIAL_SPREAD_VALUE } from './consts';

dayjs.extend(utc);

export const TransactionCoordinator = ({ children }) => {
  const { orgConfigs } = useContext(AppContext);
  const {
    mode,
    organization,
    currentTransaction,
    transactionFormValues,
    setCurrentTransaction,
    setCurrentTransactionSpread,
    transactionsBulk,
    currentBulkIndex,
    relatedTransactions,
    setRelatedTransactions,
    setRelatedTransactionsLoading,
    setReplacedByTransaction,
    setLoading,
    fetchTransactionRef,
    fetchRelatedTransactionsRef,
    changedData,
    setTransactionWithChangedData,
    setChangedDataFormatted,
    setIsTransactionLocked,
    setIsTransactionFullyLocked,
    transactionsLockBeforeDate,
    setOriginalAccountingSpreads,
    setCurrentInvoicingScheduleId,
  } = useContext(TransactionContext);
  const history = useHistory();

  const billingIsEnabledForOrg = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs });

  const fetchCurrentTransactionSpread = useCallback(
    async ({ transaction, organizationId }) => {
      let spreads = await getTransactionSpreads({ orgId: organizationId, transactionId: transaction.id });

      // this is the case when we are looking at a transaction_audit
      if (transaction.transaction_audit_spreads) {
        spreads = transaction.transaction_audit_spreads;
      }

      setCurrentTransactionSpread(
        spreads?.length
          ? {
              recognitionEvents: spreads.map((spread) => ({
                spread_id: spread.id,
                date: dayjs.utc(spread.date).toDate(),
                amount: spread.amount.toFixed(2),
                seats: spread.seats,
                percent: calculateRecognizePercent(spread.amount, transaction.amount),
                service: spread.service,
                external_id: spread.external_id,
              })),
            }
          : INITIAL_SPREAD_VALUE,
      );
    },
    [setCurrentTransactionSpread],
  );

  const fetchTransaction = useCallback(
    async (organizationId, transactionId) => {
      setLoading(true);
      const scopes = [];

      if (billingIsEnabledForOrg) scopes.push('accounting_spreads');

      try {
        const transaction = await getTransaction({
          orgId: organizationId,
          transactionId,
          params: {
            includeDealUrl: true,
            includeCustomerName: true,
            includeProductName: true,
            includeParentCustomer: true,
            includeReplacedTransactions: true,
            includeExternalUpdates: true,
            scopes,
          },
        });
        normalizeAmountFields(transaction);
        setCurrentTransaction(transaction);
        setCurrentInvoicingScheduleId(transaction?.invoicing_schedule_id);
        setOriginalAccountingSpreads(transaction.accounting_spreads);
        await fetchCurrentTransactionSpread({ transaction, organizationId });
        setLoading(false);
      } catch (err) {
        history.push('/transactions');
      }
    },
    [
      fetchCurrentTransactionSpread,
      setLoading,
      setCurrentTransaction,
      history,
      setOriginalAccountingSpreads,
      billingIsEnabledForOrg,
      setCurrentInvoicingScheduleId,
    ],
  );

  const fetchRelatedTransactions = async ({ customerId }) => {
    setRelatedTransactionsLoading(true);
    const response = await getTransactions({
      orgId: organization.id,
      filters: {
        page: 1,
        limit: 250,
        body: { customerIds: [customerId] },
        params: {
          archived: false,
        },
      },
    });
    const relatedTransactionsWithoutCurrentTransaction =
      response?.data?.filter((transaction) => transaction.id !== currentTransaction?.id) ?? [];
    const relatedTransactionsSortedByEndDates = relatedTransactionsWithoutCurrentTransaction
      .sort((a, b) => compareDate(a.end_date, b.end_date))
      .reverse();
    setRelatedTransactions(relatedTransactionsSortedByEndDates);
    setRelatedTransactionsLoading(false);
  };

  useEffect(() => {
    const isTransactionLockedByDateBefore = isTransactionLocked({
      transaction: {
        date: transactionFormValues?.date,
        start_date: transactionFormValues?.start_date,
        end_date: transactionFormValues?.end_date,
        confirmed: currentTransaction?.confirmed,
      },
      transactionsLockBeforeDate,
    });

    const isTransactionFullyLockedByDateBefore = isTransactionLocked({
      transaction: {
        date: currentTransaction?.date,
        start_date: currentTransaction?.start_date,
        end_date: currentTransaction?.end_date,
        confirmed: currentTransaction?.confirmed,
      },
      transactionsLockBeforeDate,
    });

    setIsTransactionLocked(isTransactionLockedByDateBefore);
    setIsTransactionFullyLocked(isTransactionFullyLockedByDateBefore);
  }, [
    currentTransaction.confirmed,
    currentTransaction.replaced_by,
    currentTransaction?.end_date,
    currentTransaction?.start_date,
    currentTransaction?.date,
    transactionFormValues?.end_date,
    transactionFormValues?.start_date,
    transactionFormValues?.date,
    setIsTransactionLocked,
    setIsTransactionFullyLocked,
    transactionsLockBeforeDate,
  ]);

  useEffect(() => {
    if (!currentTransaction.id) return;
    const isTransactionSufficient = Object.keys(currentTransaction).length > 2;
    if (isTransactionSufficient) {
      normalizeAmountFields(currentTransaction);
      setCurrentTransaction(currentTransaction);
      fetchCurrentTransactionSpread({ transaction: currentTransaction, organizationId: organization.id });
    } else {
      fetchTransaction(organization.id, currentTransaction.id);
    }
  }, [fetchTransaction, fetchCurrentTransactionSpread, setCurrentTransaction, organization.id, currentTransaction]);

  useEffect(() => {
    if (mode === TRANSACTION_MODAL_MODE.EDIT_BULK && currentBulkIndex <= transactionsBulk.length - 1) {
      normalizeAmountFields(transactionsBulk[currentBulkIndex]);
      setCurrentTransaction(transactionsBulk[currentBulkIndex]);
    }
  }, [setCurrentTransaction, currentBulkIndex, transactionsBulk, mode]);

  // If this transaction is replacing a transaction, we should fetch that to show its name, etc
  //  since we don't know if this transaction replaces anything, we need to do this every time the modal is loaded
  //  so we can show the appropriate replacement relationship
  useEffect(() => {
    organization.id && currentTransaction.id && fetchTransaction(organization.id, currentTransaction.id);
    // Doing this because if we make currentTransaction a dependency, we end up in an infinite loop fetching
    //  the replaced transaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  useEffect(() => {
    if (currentTransaction.customer_id) {
      fetchRelatedTransactions({ customerId: currentTransaction.customer_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransaction.id, currentTransaction.customer_id, organization.id]);

  // If this transaction is replaced by another transaction, we should fetch that to show its name, etc
  useEffect(() => {
    if (currentTransaction?.replaced_by && relatedTransactions.length) {
      //In order not to make an extra request, we find the replaced by transaction in related transactions
      const replaceTransaction = relatedTransactions.find(
        (relatedTransaction) => +relatedTransaction.id === +currentTransaction.replaced_by,
      );
      setReplacedByTransaction(replaceTransaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransaction.replaced_by, relatedTransactions, organization.id]);

  // We want to keep transactionWithChangedData in sync
  // since the currentTransaction might change
  // (for example, in the auto-fetch useEffect hook, if we provide an id instead of the entire object)
  useEffect(() => {
    const transactionData = { ...currentTransaction, ...changedData };

    normalizeAmountFields(transactionData);
    setTransactionWithChangedData(transactionData);
  }, [changedData, currentTransaction, setTransactionWithChangedData]);

  // We keep a formatted object of the changed data so we can easily extract what we need
  // throughout the modal
  useEffect(() => {
    setChangedDataFormatted(prepareData({ originalData: currentTransaction, updateData: changedData }));
  }, [changedData, currentTransaction, setChangedDataFormatted]);

  fetchTransactionRef.current = fetchTransaction;
  fetchRelatedTransactionsRef.current = fetchRelatedTransactions;

  return <>{children}</>;
};
