import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';
import { useToasts } from 'components/Toasts';
import { bulkUpdateAccountingSpreads as _bulkUpdateAccountingSpreads } from './requests';

export const CACHE_KEY = 'accountingSpreads';

export const useAccountingSpreadsAPI = ({ orgId, enableToasts = true }) => {
  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const bulkUpdateAccountingSpreads = useDelegatedMutationPolling({
    mutationFn: async ({ data }) => _bulkUpdateAccountingSpreads({ orgId, data }),
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Bulk updating accounting spreads is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish bulk updating accounting spreads. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(
          `Bulk updating accounting spreads is taking long to complete for org ${orgId}`,
          'warning',
        );
      },
      onError: (err) => {
        pushError(
          err,
          `Failed to bulk update accounting spreads. ${err?.response?.data?.error ?? err.message ?? err}`,
          -1,
        );
      },

      onSuccess: () => {
        pushToast('Successfully bulk updated accounting spreads!', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  return {
    operations: {
      bulkUpdateAccountingSpreads: bulkUpdateAccountingSpreads.mutateAsync,
    },
  };
};
